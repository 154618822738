/* CSS Global */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
  background-color: #f8f8f8;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI",
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-top: 80px;
}

@media (min-width: 1297px) {
  body {
    margin-top: 130px;
  }
}

a {
  color: #686464;
}

.title-custom-h2 {
  margin: 20px 0;
  font-weight: 550;
  color: rgb(103, 96, 96);
  text-transform: uppercase;
}

.title-custom-h3 {
  margin: 20px 0;
  font-weight: 550;
  color: rgb(103, 96, 96);
  text-transform: uppercase;
}

.btn-create {
  color: #fff !important;
  background-color: #faa00a !important;
  border-color: #faa00a !important;
  width: 150px;
  height: 40px;
  border-radius: 25px !important;
  padding-top: 8px;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-create:hover {
  color: #000 !important;
  /* background-color: #fff !important; */
}

.btn-access {
  color: #fff !important;
  background-color: #8498c1 !important;
  border-color: #7d96c5 !important;
  width: 150px;
  height: 40px;
  border-radius: 25px !important;
  padding-top: 10px;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-info-status {
  color: #fff !important;
  background-color: #faa00a !important;
  border-color: #faa00a !important;
  width: 150px;
  height: 54px;
  padding: 12px;
  padding-top: 10px;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-trans-option {
  height: 50px;
  width: 260px;
  border: #dedede solid 1px;
  background-color: #fff;
  margin: 20px 5px;
  border-radius: 0;
}

.btn-cancel {
  color: #686464 !important;
  border-color: #faa00a !important;
  width: 150px;
  height: 40px;
  background-color: #fff;
  border-radius: 25px !important;
  margin: 10px 0 0 0;
  padding: 0.475rem 0.75rem;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-link {
  color: #686464 !important;
  border-color: #fff !important;
  width: 100px;
  height: 40px;
  background-color: #fff;
  border-radius: 25px !important;
  margin: 10px 0 0 0;
  padding: 0.475rem 0.75rem;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-link:hover {
  color: #614e4e !important;
  background-color: #fff;
}

.btn-none {
  background: none;
  border: none;
}

.btn-login {
  color: #fff !important;
  border-radius: 25px !important;
  margin-left: 45px !important;
  font-size: 14px !important;
}

.btn-login b {
  color: #faa00a !important;
}

.vl {
  margin-left: 15px;
  font-weight: 600;
  color: #faa00a;
}

.form-group input,
.form-group select,
.form-group textarea {
  margin-top: 5px;
  border-radius: 25px;
}

#loading {
  width: 100%;
  height: 100%;
  background: #0708085e;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#loading .spinner-border {
  color: #ff8400;
  width: 5rem;
  height: 5rem;
}

.kep-login-facebook {
  margin-left: 10px;
  border-radius: 3px;
  width: 50px;
  height: 52.5px;
  padding: 7px 10px 10px 10px !important;
}

.carousel-root {
  cursor: pointer;
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: #f8f8f8;
}

.carousel .slide iframe {
  width: calc(100% - 0px);
  margin: 0 0px 40px;
}

/* .modal-content {
  background-color: #cdc6c6;
} */

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
