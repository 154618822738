@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
/* Carossel top */

@media (min-width: 2400px) {
  #carousel-custom img {
    height: 64vh !important;
    width: 100% !important;
  }

  #carousel-custom .carousel-item-custom {
    width: 100%;
  }
}

@media (min-width: 1400px) {
  #carousel-custom img {
    height: 42vh !important;
  }
}

@media only screen and (max-width: 767px) and (max-height: 900px) {
  #carousel-custom img {
    height: 31vh !important;
    width: 100% !important;
    margin: 0 !important;
    border-radius: 0px !important;
  }

  #carousel-custom .carousel-item-custom {
    width: 100%;
    margin: 0 !important;
  }
}

#carousel-custom img {
  height: 59vh;
  margin: 0 2vh;
  border-radius: 15px;
  object-fit: cover;
}

#carousel-custom .BrainhubCarousel__arrowRight {
  position: absolute;
  right: 15px;
  font-size: 0;
  background-color: orange;
  border-radius: 15px;
  box-shadow: 1px 1px 20px rgb(0 0 0);
}

#carousel-custom .BrainhubCarousel__arrowLeft {
  position: absolute;
  left: 15px;
  font-size: 0;
  background-color: orange;
  border-radius: 15px;
  box-shadow: 1px 1px 20px rgb(0 0 0);
  z-index: 10;
}

#carousel-custom .BrainhubCarousel__dots {
  margin-top: -70px;
}

#carousel-custom .BrainhubCarousel__dots .BrainhubCarousel__dot {
  background-color: #fff;
}

#carousel-custom .d-flex {
  display: none !important;
}

.carousel-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#carousel-custom .carousel-item-custom {
  width: 100%;
  margin: 0 10px;
}

#carousel-custom .carousel-caption-custom {
  color: #fff;
  position: relative;
  top: -55px;
  left: 15px;
  padding: 0 10px 0 20px;
  height: 55px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-bottom: 5vh;
  /* -webkit-box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.4); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), gray); */
}

#carousel-custom .carousel-caption-custom h2 {
  text-transform: uppercase;
  font-weight: 600;
}

@media (max-width: 767px) {
  #carousel-custom .carousel-caption-custom {
    color: #fff;
    position: relative;
    top: -40px;
    left: 17px;
    padding: 0 10px 0 20px;
    height: 35px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    /* -webkit-box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.4);
        box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.4); */
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), black); */
  }

  #carousel-custom .carousel-caption-custom h2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
  }

  #carousel-custom .BrainhubCarousel__dots {
    margin-top: -39px;
  }
}

@media (max-width: 660px) {
  #carousel-custom .carousel-caption-custom h2 {
    text-transform: uppercase;
    font-weight: 550;
    font-size: 1.1rem;
  }
}

@media (max-width: 360px) {
  #carousel-custom .carousel-caption-custom {
    color: #fff;
    position: relative;
    top: -40px;
    left: 13px;
    padding: 0 10px 0 20px;
    height: 40px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    /* -webkit-box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.4);
        box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.4); */
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), black); */
  }
}

#carousel-custom .BrainhubCarousel__dots .BrainhubCarousel__dot {
  background: none;
}

#carousel-custom .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  width: 20px;
  height: 20px;
  background: #faa00a;
}

#carousel-custom .carousel-caption {
  right: 0%;
  padding: 14px;
  left: 0%;
  text-align: justify;
  font-size: 1.2rem;
  font-weight: 545;
  /* -webkit-box-shadow: 1px -9px 7px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 1px -9px 7px rgba(0, 0, 0, 0.4); */
  /* box-shadow: 1px -9px 7px rgba(0, 0, 0, 0.4); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), black); */
}

#carousel-custom .carousel-caption h3 {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 550;
}

/* Section for product */

#option-product {
  margin-top: 3%;
}

#option-product .item img {
  border-radius: 20px;
}

#option-product .item .figure {
  max-width: 42vh !important;
  margin: 1vh 1rem !important;
}

@media (max-width: 920px) {
  #option-product .item .figure-caption h3 {
    font-size: 1.2rem;
  }

  #option-product .item .figure {
    width: 23vh;
    max-width: 23vh !important;
    margin: 0px -1.2vh !important;
  }

  #option-product .item .figure-caption {
    color: white;
    top: -41px !important;
    height: 35px !important;
    width: 100%;
    padding: 2px 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    position: relative;
    /* box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.4);
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(75, 75, 75)) */
  }
}

@media only screen and (min-width: 767px) and (min-height: 1024px) {
  #option-product .item .figure {
    width: 10.5rem;
    max-width: 25vh !important;
    margin: 0px -1vh !important;
  }
}

@media (min-width: 1800px) {
  #option-product .item .figure {
    width: 29vh;
    max-width: 25vh !important;
    margin: 0px -1vh !important;
    padding: 0vh 2vh !important;
  }
}

#option-product .item .figure-caption {
  color: white;
  top: -55px;
  height: 52px;
  width: 100%;
  padding: 5px 10px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  position: relative;
  /* box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(75, 75, 75)) */
}

/* Section most accessed product */

#most-accessed-product {
  margin-top: 20px;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 40px;
}

#most-accessed-product .BrainhubCarousel__arrowRight {
  right: 20px;
  border: 1px solid #d6d4d430;
  border-radius: 50px;
  color: #000;
  font-size: 0;
  background-color: #ffffff;
  z-index: 25;
}

#most-accessed-product .BrainhubCarousel__arrowLeft {
  left: 20px;
  border: 1px solid #d6d4d430;
  border-radius: 50px;
  color: #000;
  font-size: 0;
  background-color: #ffffff;
  z-index: 25;
}

#most-accessed-product .BrainhubCarousel__arrows span {
  border-color: #000;
}

#most-accessed-product .card {
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
  margin: 1.5vh;
  border-radius: 25px;
}

@media (min-width: 1024px) {
  #most-accessed-product .card {
    box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    margin: 0.5vh;
    border-radius: 25px;
  }

  #most-accessed-product .text-value {
    margin-top: 3.5vh !important;
  }
}

#most-accessed-product .card a:hover,
#most-accessed-product .card a:visited,
#most-accessed-product .card a:focus,
#most-accessed-product .card a:active {
  text-decoration: none;
  color: inherit;
}

#most-accessed-product img {
  margin: 0 0vh;
  height: 180px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  object-fit: cover;
}

#most-accessed-product .img-vendida img {
  -webkit-filter: gray;
          filter: gray;
  /* IE6-9 */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
}

#most-accessed-product .card .card-body {
  height: 200px;
}

#most-accessed-product .card .card-title {
  font-weight: 600;
  height: 40px;
}

#most-accessed-product .card .card-text {
  color: rgb(103, 96, 96);
  height: 50px;
}

#most-accessed-product .text-ticket {
  color: #faa00a;
}

#most-accessed-product .text-value {
  color: #faa00a;
  margin-top: 5vh;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}

#most-accessed-product figure,
#new-product figure {
  position: relative;
}

.tarja-vendido {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-shadow: 2px 2px 4px #000000;
  color: #fffdf9;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  border-radius: 25px 25px 0px 0px;
}

.tarja-nao-vendido {
  display: none !important;
}

.tarja-vendido:hover {
  background-color: none;
}

/* Section new product */

#new-product {
  margin-top: 75px;
}

#new-product .BrainhubCarousel__arrowRight {
  right: 20px;
  border: 1px solid #d6d4d430;
  border-radius: 50px;
  color: #000;
  font-size: 0;
  background-color: #ffffff;
  z-index: 25;
}

#new-product .BrainhubCarousel__arrowLeft {
  left: 20px;
  border: 1px solid #d6d4d430;
  border-radius: 50px;
  color: #000;
  font-size: 0;
  background-color: #ffffff;
  z-index: 25;
}

#new-product .BrainhubCarousel__arrows span {
  border-color: #000;
}

@media (min-width: 1024px) {
  #new-product .card {
    margin: 0.5vh !important;
  }

  #new-product .text-value {
    margin-top: 2.5vh !important;
  }
}

#new-product .card {
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
  margin: 1.5vh;
  border-radius: 25px;
}

#new-product .card a:hover,
#new-product .card a:visited,
#new-product .card a:focus,
#new-product .card a:active {
  text-decoration: none;
  color: inherit;
}

#new-product img {
  margin: 0 0vh;
  height: 180px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  object-fit: cover;
}

#new-product .card .card-body {
  height: 200px;
}

#new-product .card .card-title {
  font-weight: 600;
  height: 40px;
}

#new-product .card .card-text {
  height: 50px;
  color: rgb(103, 96, 96);
}

#new-product .text-ticket {
  color: #faa00a;
}

#new-product .text-value {
  color: #faa00a;
  margin-top: 5vh;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}

/* Partener */

#partner {
  padding-top: 25px;
  padding-bottom: 45px;
  margin-top: 75px;
  margin-bottom: 30px;
  background-color: white;
}

#partner .BrainhubCarousel__arrowRight {
  right: 20px;
  border: 1px solid #d6d4d430;
  border-radius: 50px;
  color: #000;
  font-size: 0;
  background-color: #ffffff;
  z-index: 25;
}

#partner .BrainhubCarousel__arrowLeft {
  left: 20px;
  border: 1px solid #d6d4d430;
  border-radius: 50px;
  color: #000;
  font-size: 0;
  background-color: #ffffff;
  z-index: 25;
}

#partner .BrainhubCarousel__arrows span {
  border-color: #000;
}

#partner .card {
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
  margin: 1.5vh;
  min-width: 90%;
  max-width: 90%;
  max-height: 95%;
  min-height: 95%;
  border-radius: 25px;
}

@media (max-width: 720px) {
  #partner .card {
    box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    margin: 4px;
    min-width: 70%;
    max-width: 70%;
    max-height: 90%;
    min-height: 90%;
    border-radius: 25px;
  }

  #partner .card .card-body {
    padding: 2.5vh !important;
  }
}

#partner img {
  margin: 0 2vh;
  width: 86%;
  text-align: center;
  vertical-align: middle;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

#partner .card .card-title {
  font-weight: 600;
  text-align: center;
}

#partner .card .card-body {
  padding: 0.5vh;
}

.herd-bovinos-partner {
  padding-top: 2.5vh;
  padding-bottom: 0.5vh;
}

.center-gen-partner {
  padding-top: 10vh !important;
  padding-bottom: 6vh !important;
}

.marchangus-partner {
  margin-top: 6.5vh !important;
  padding-bottom: 2vh !important;
}

.sudotec-partner {
  margin-top: 6.5vh !important;
  padding-bottom: 1.5vh !important;
  overflow: hidden;
}

.sicoob-partner {
  padding-top: 10vh !important;
  padding-bottom: 4.5vh;
}

.thinks-partner {
  padding-top: 6vh !important;
  padding-bottom: 0.5vh;
}

/* Connect businesses */

#connectbusinesses {
  margin-top: 30px;
  margin-bottom: -40px;
}

#connectbusinesses .figure-img {
  margin-top: 90px;
}

#connectbusinesses h2 {
  margin-bottom: 50px;
}

#connectbusinesses p {
  text-align: justify;
}

#connectbusinesses .btn {
  border-radius: 20px;
  border-color: #faa00a;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #faa00a;
  color: white;
  width: 290px;
  height: 40px;
  margin-left: 19vh;
}

@media (min-width: 1024px) {
  #connectbusinesses .btn {
    border-radius: 20px;
    border-color: #faa00a;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #faa00a;
    color: white;
    width: 290px;
    height: 40px;
    margin-left: 6vh;
  }
}

#connectbusinesses .btn:focus {
  color: #faa00a;
  border-color: #faa00a;
}

@media (max-width: 767px) {
  #connectbusinesses .btn {
    margin-left: 4vh;
  }
}

#connectbusinesses .btn:hover {
  background-color: white;
  color: #faa00a;
  border-color: #faa00a;
}

#navbar-custom-footer {
    padding-top: 5px;
    text-transform: uppercase;
}

#info-footer-custom {
    margin: 100px 0px;
    margin-bottom: 0px !important;
}

#info-footer-custom .pay,
.safe,
.tracking {
    padding: 20px 0;
}

#info-footer-custom .pay b,
.safe b,
.tracking b {
    color: rgb(104, 100, 100);
    top: 20px;
    padding: 10px 0;
    position: relative;
    text-transform: uppercase;
}

#info-footer-custom .pay p,
.safe p,
.tracking p {
    color: rgb(104, 100, 100);
    top: 20px;
    position: relative;
}

#wpp-btn {
    position: fixed;
    width: 62px;
    height: 62px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;
}

#wpp-btn i {
    margin-top:16px
}

#navbar-custom-footer .icon-bell {
    color: #000;
}

.bg-clear-custom {
    background-color: #fff;
}

.bg-clear-custom-rodape {
    background-color: #fd880d;
    height: 18vh;
}

.ctn-rodape {
    background-color: #fd880d;
}

@media(max-width: 370px) {
    .bg-clear-custom-rodape {
        height: 31vh;
    }
}

#nav-footer-custom {
    font-weight: 650;
    font-size: 1.01rem;
}

.rodape {
    padding-top: 15px;
}

#nav-footer-custom .nav-link,
#nav-footer-social-custom .nav-link {
    margin: 0 5px;
    color: #fff;
}

#nav-footer-social-custom {
    margin-left: 143px;
    color: #fff;
}

@media (max-width: 1199px) {
    #nav-footer-social-custom {
        margin-left: 0px;
    }
}

#contact p, #contact b {
    color: #686464;
}

#contact .whatsapp {
    margin: 15px 0;
}

#contact .phone {
    margin: 15px 0;
}

#contact .map {
    margin: 15px 0;
}

#contact .mapAlt {
    margin: 15px 5px;
}

#contact .email {
    margin: 15px 0;
}

#contact .icon-whatsapp {
    color: #faa00a;
    margin-left: 4px;
    margin-right: 16px;
}

#contact .icon-phone {
    color: #faa00a;
    margin-right: 15px;
}

#contact .icon-map {
    color:#faa00a;
    margin-right: 15px;
}

#contact .icon-mapAlt {
    color:#faa00a;
    margin-right: 18px;
}

#contact .icon-email {
    color: #faa00a;
    margin-right: 12px;
}

#contact .info p {
    font-size: 20px;
    display: contents;
    font-weight: 500;
}

#contact .title-custom-h2 {
    margin: 60px 0;
}
#help-me {
    padding: 20px;
}

#help-me .description-title {
    font-size: 19px;
    padding: 10px 0px;
    text-align: center;
}

#help-me .btn-link{
    width: 100%;
    text-align: start;
    font-size: 16px!important;
    background: #f7f7f7;
}

#help-me .btn-link {
    margin-top: -10px
}

#help-me .card-header {
    height: 15vh;
}

#help-me .btn-link b{
    font-size: 20px;
    font-weight: 700;
    color:  #faa00a;;
}
#download-app-bg {
    background-image: url(/static/media/bg-donwload-app.46459c21.jpg);
	background-repeat: no-repeat;
    background-position: center;
    background-size: 210vh;
    height: 82vh;
    display: flex;
    display: -webkit-flex; /* Garante compatibilidade com navegador Safari. */
    object-fit: cover;
}

#download-app {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: 100px;
    
}

#download-app .title-custom-h2 {
    color: #FFF;
    text-transform: inherit;
}

#download-app .btn-download img{
    width: 40%;
}

@media (max-width: 668px) {
    #download-app .btn-download img{
        width: 100%!important;
    }

    #download-app {
        padding-left: 5px;
    }
    
}
#about-us .about-leiloae {
    border-radius: 15px;
    padding: 50px;
    margin-top: 50px;
}

#about-us h1{
    text-transform: uppercase;
    color: #666666;
    font-weight: 600
}

#about-us .about-leiloae h2{
    text-transform: uppercase;
    color: #666666;
}

#about-us .about-leiloae .about-img {
    display:         flex;
    display: -webkit-flex; /* Garante compatibilidade com navegador Safari. */
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
}

@media (max-width: 350px){
    .about-mission {
        width: 40vh;
    }
}

#about-us .about-leiloae .about-text {
    text-align: justify;
}

#about-us .about-leiloae .about-info h1{
    text-transform: uppercase;
    color: #666666;
    font-weight: 600
}

#about-us .about-leiloae .about-info p{
    color: #989c9f;
}

#about-us .about-leiloae p li{
    margin-bottom: 5px;
    text-align: justify;
}

#about-us .team-title {
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: justify;
}

#about-us .about-team {
    border-radius: 15px;
    padding: 10px 0;
    text-align: justify;
}

#about-us .about-team h6 {
    text-transform: uppercase;
    font-weight: 550;
    color: #666666;
}

#about-us .join-us .btn-create {
    width: auto;
}

#product {
    margin-top: 30px;
}

#product .col-12 {
    padding-right: 0px;
}

#product .menu-left .title-custom-h2{
    margin-left: -15px;
}

#product .menu-left .navbar {
    padding: 0 1rem 1rem 0;
}

#product .menu-left p{
    margin-top: -22px;
    font-size: 13px;
    color: #676060;
}

#product .menu-left h5 {
    margin: 13px 0;
}

#product .menu-left .form-group {
    width: 100%;
}

#product .menu-left .form-group .row {
    margin: 0;
}

#product .menu-left .form-group .row  input {
    margin: 0 5px 0 0;
}

#product .menu-left .form-group .row .rc-slider {
    margin-top: 20px;
}


#product .menu-left .form-group .accordion .card {
    border-radius: 15px;
    border: none;
}

#product .menu-left .form-group .accordion .card .card-header {
    background-color: #fff;
    border-bottom: none;
    padding: .75rem 0.25rem;
}

#product .menu-left .form-group .accordion .card .card-header button{
    width: 100%;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
}

#product .menu-left .form-group .accordion .card .card-header button b b{
    float: right;
}

#product .menu-left .form-group .accordion .card .collapse .card-body input, #product .menu-left .form-group .accordion .card .collapse .card-body select{
    border-radius: 15px;
}

#product .colunm {
    padding: 10px 20px;
    border-radius: 15px;
}

#product .colunm .col-12, #product .colunm .col-sm-3, #product .colunm .col-sm-9 {
    padding-right: 0px;
    padding-left: 0px;
}

#product .list-right figure {
    margin: 0;
    min-width: 100%;
}

#product .list-right figure .figure-img {
    margin-bottom: 0;
    border-radius: 15px;
    width: 100%;
    height: 200px;
    object-fit: cover;
}

@media (max-width: 991px) {
    #product .list-right figure .figure-img {
        height: auto;
    }
}


#product .list-right .info-lots{
    padding-right: 15px;
    padding-left: 15px;
}

#product .list-right .info-lots .titles h2{
    margin: 10px 10px;
    font-weight: 600;
    font-size: 1.5rem;
}

@media (max-width: 991px) {
    #product .list-right .info-lots .titles h2{
        font-size: 1.5rem;
    }
}

#product .list-right .info-lots .titles {
    justify-content: space-between;
}

#product .list-right .info-lots .titles p{
    margin: 10px 3px;
    background-color:#faa00a;
    float: right;
    padding: 5px 7px;
    font-size: 13px;
    border-radius: 25px;
    color: #fff;
    display:flex;
    justify-content:right
}

@media (max-width: 991px) {
    #product .list-right .info-lots .titles p{
        padding: 7px 15px;
        margin: 0px 10px 10px 10px;
        font-size: 10px;
    }
}

#product .list-right .info-lots .description p{
    color: #676060;
    text-align:left;
}

#product .list-right .info-lots .vl-show-details {
    width: 100%;
    margin-top: 50px;
}
@media (max-width: 991px) {
    #product .list-right .info-lots .vl-show-details {
        width: 100%;
        margin-top: 10px;
    }
}

#product .list-right .info-lots .vl-show-details .type-coin {
    width: 100%;
    margin: 0px 0px -7px 30px;
    color: #faa00a;
}

@media (max-width: 768px) {
    #product .list-right .info-lots .vl-show-details .type-coin {
        width: 100%;
        margin: 0px 70px -7px 0px;
        color: #faa00a;
    }
}

#product .list-right .info-lots .vl-show-details .vl {
    margin-left: 15px;
    font-weight: 600;
    color: #faa00a;
}

@media (max-width: 991px) {
    #product .list-right .info-lots .vl-show-details .vl {
        font-size: 1.3rem;
    }
}

#product .list-right .info-lots .vl-show-details a {
    margin: 0px;
    padding: 0.55rem .75rem;
    width: 150px;
}

@media (max-width: 991px) {
    #product .list-right a {
        margin: 0px;
        width: 100%;
    }
}

#product  .navbar-light .form-check {
    position: relative;
    display: block;
    padding-left: 3.25rem;
}

#product  .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    background-color: rgb(243, 232, 232);
    border-color: rgba(20, 20, 20, 0.575);
}

#product  .navbar-light .navbar-toggler-icon {
    width: 100%;
    padding: 5px 0;
    color: #000;
    background-image: none;
}

#product  .navbar-light .navbar-toggler-icon:before {
    content: 'Filtro';
    width: 100%;
    padding: 5px 0;
    color: #000;
}

#product .btn-modal {
    color: #faa00a!important;
    background-color: #fff!important;
    border-color: #faa00a!important;
    width: 102px;
    height: 28px;
    border-radius: 25px!important;
    padding-top: 7px;
    margin: 10px 0 0 0;
    text-transform: uppercase;
    font-weight: 600!important;
    font-size: 11px!important;
}

#product .btn-modal:hover {
    background-color: #000 !important;
    border-color: #faa00a;
}

#product .btn-modal:focus {
    color:#fff;
    background-color: #faa00a;
    border-color: #faa00a;
    box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 25%) !important;
}

#product .card-info-lots {
    background: #e7dddd;
    padding: 20px;
    border-radius: 15px;
    color: #676060;
    font-weight: 700;
}

#product .card-info-lots .btn-create {
    width: auto;
    font-size: 18px!important;
    background-color: #039928!important;
}

#product .card-info-lots .btn-create svg{
    margin-left: 10px;
}
#product .col-12 {
    padding-right: 0px;
}

#product .menu-left .title-custom-h2{
    margin-left: -15px;
}

#product .menu-left .navbar {
    padding: 0 1rem 1rem 0;
}

#product .menu-left p{
    margin-top: -22px;
    font-size: 13px;
    color: #676060;
}

#product .menu-left h5 {
    margin: 13px 0;
}

#product .menu-left .form-group {
    width: 100%;
}

#product .menu-left .form-group .row {
    margin: 0;
}

#product .menu-left .form-group .row  input {
    margin: 0 5px 0 0;
}

#product .menu-left .form-group .row .rc-slider {
    margin-top: 20px;
}


#product .menu-left .form-group .accordion .card {
    border-radius: 15px;
    border: none;
}

#product .menu-left .form-group .accordion .card .card-header {
    background-color: #fff;
    border-bottom: none;
    padding: .75rem 0.25rem;
}

#product .menu-left .form-group .accordion .card .card-header button{
    width: 100%;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
}

#product .menu-left .form-group .accordion .card .card-header button b b{
    float: right;
}

#product .menu-left .form-group .accordion .card .collapse .card-body input, #product .menu-left .form-group .accordion .card .collapse .card-body select{
    border-radius: 15px;
}

#product .colunm {
    padding: 10px 20px;
    border-radius: 15px;
}

#product .colunm .col-12, #product .colunm .col-sm-3, #product .colunm .col-sm-9 {
    padding-right: 0px;
    padding-left: 0px;
}

#product .list-right figure {
    margin: 0;
    min-width: 100%;
}

#product .list-right figure .figure-img {
    margin-bottom: 0;
    border-radius: 15px;
    width: 100%;
    height: 200px;
    object-fit: cover;
}

@media (max-width: 991px) {
    #product .list-right figure .figure-img {
        height: auto;
    }
}


#product .list-right .info-lots{
    padding-right: 15px;
    padding-left: 15px;
}

#product .list-right .info-lots .titles h2{
    margin: 10px 10px;
    font-weight: 600;
    font-size: 1.5rem;
}

@media (max-width: 991px) {
    #product .list-right .info-lots .titles h2{
        font-size: 1.5rem;
    }
}

#product .list-right .info-lots .titles {
    justify-content: space-between;
}

#product .list-right .info-lots .titles p{
    margin: 10px 3px;
    background-color:#faa00a;
    float: right;
    padding: 5px 7px;
    font-size: 13px;
    border-radius: 25px;
    color: #fff;
    display:flex;
    justify-content:right
}

@media (max-width: 991px) {
    #product .list-right .info-lots .titles p{
        padding: 7px 15px;
        margin: 0px 10px 10px 10px;
        font-size: 10px;
    }
}

#product .list-right .info-lots .description p{
    color: #676060;
    text-align:left;
}

#product .list-right .info-lots .vl-show-details {
    width: 100%;
    margin-top: 50px;
}
@media (max-width: 991px) {
    #product .list-right .info-lots .vl-show-details {
        width: 100%;
        margin-top: 10px;
    }
}

#product .list-right .info-lots .vl-show-details .type-coin {
    width: 100%;
    margin: 0px 0px -7px 30px;
    color: #faa00a;
}

@media (max-width: 768px) {
    #product .list-right .info-lots .vl-show-details .type-coin {
        width: 100%;
        margin: 0px 70px -7px 0px;
        color: #faa00a;
    }
}

#product .list-right .info-lots .vl-show-details .vl {
    margin-left: 15px;
    font-weight: 600;
    color: #faa00a;
}

@media (max-width: 991px) {
    #product .list-right .info-lots .vl-show-details .vl {
        font-size: 1.3rem;
    }
}

#product .list-right .info-lots .vl-show-details a {
    margin: 0px;
    padding: 0.55rem .75rem;
    width: 150px;
}

@media (max-width: 991px) {
    #product .list-right a {
        margin: 0px;
        width: 100%;
    }
}

#product  .navbar-light .form-check {
    position: relative;
    display: block;
    padding-left: 3.25rem;
}

#product  .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    background-color: rgb(243, 232, 232);
    border-color: rgba(20, 20, 20, 0.575);
}

#product  .navbar-light .navbar-toggler-icon {
    width: 100%;
    padding: 5px 0;
    color: #000;
    background-image: none;
}

#product  .navbar-light .navbar-toggler-icon:before {
    content: 'Filtro';
    width: 100%;
    padding: 5px 0;
    color: #000;
}

#product .btn-modal {
    color: #faa00a!important;
    background-color: #fff!important;
    border-color: #faa00a!important;
    width: 102px;
    height: 28px;
    border-radius: 25px!important;
    padding-top: 7px;
    margin: 10px 0 0 0;
    text-transform: uppercase;
    font-weight: 600!important;
    font-size: 11px!important;
}

#product .btn-modal:hover {
    background-color: #000 !important;
    border-color: #faa00a;
}

#product .btn-modal:focus {
    color:#fff;
    background-color: #faa00a;
    border-color: #faa00a;
    box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 25%) !important;
}

#product .card-info-lots {
    background: #e7dddd;
    padding: 20px;
    border-radius: 15px;
    color: #676060;
    font-weight: 700;
}

#product .card-info-lots .btn-create {
    width: auto;
    font-size: 18px!important;
    background-color: #039928!important;
}

#product .card-info-lots .btn-create svg{
    margin-left: 10px;
}

#product > div {
    margin-left: 0 !important;
}

#animalCards {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    gap: 24px;
}

@media screen and (max-width: 405px) {
    #animalCards {
        padding-left: 8px;
    }
}

@media screen and (min-width: 768px) {
    #filtro {
        flex: 0 0 40%;
        max-width: 40%;
    }

    #animalCards {
        flex: 0 0 60%;
        max-width: 60%;
    }
}

@media screen and (min-width: 1024px) {
    #filtro {
        flex: 0 0 25%;
        max-width: 25%;
    }

    #animalCards {
        flex: 0 0 75%;
        max-width: 75%;
    }
}

@media screen and (min-width: 1024px) {
    #container {
        max-width: 1010px !important;
    }
}

@media screen and (min-width: 1200px) {
    #container {
        max-width: 1140px !important;
    }
}

@media screen and (min-width: 1506px) {
    #container {
        max-width: 1600px !important;
    }
}

.banner {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    -webkit-transform: translateY(130px);
            transform: translateY(130px);
}

.banner img {
    width: 100%;
}
p, b {
    color: #686464;
}

@media screen and (min-width: 768px) {
    #container {
        max-width: 960px;
    }
}

@media screen and (min-width: 1200px) {
    #container {
        max-width: 1140px;
    }
}

.actionSection {
    display: flex;
    flex-wrap: wrap;
}

#product-detail .row h2 {
    font-weight: 700;
    margin-top: 30px;
}

#product-detail .call-to-action-detail{
    width: 31%;
    margin: 10px 0 0 10px;
}

#product-detail .visitors {
    /* margin-top: 5px; */
    margin-top:10%;
    vertical-align: middle;
}

.carousel .slide img {
    max-width:100vh;
    max-height:71vh;

    text-align: center !important;
    width: auto !important;
    height: auto !important;
}

.image-gallery-slide img {
    height: 50vh;
}

.my-slide .thumb-img-video {
    display: none!important;
}

#product-detail {
    padding-top: 30px;    
}

@media screen and (min-width: 1297px) {
    #product-detail {
        padding-top: 60px;
    }
}

@media screen and (min-width: 1381px) {
    #product-detail {
        padding-top: 30px;
    }
}

#product-detail .row-top {
    margin-bottom: 20px;
    margin-top: 15px;
}

#product-detail .row p {
    margin-bottom: 30px;
}

#product-detail .row .image-gallery .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    border-radius: 15px;
}
#product-detail .row .image-gallery .image-gallery-thumbnail .image-gallery-thumbnail-image {
    border-radius: 10px;
}

#product-detail .row .image-gallery .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    border-radius: 15px;
}

#product-detail .row .image-gallery .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 40px;
    width: 40px;
}

#product-detail .row .detail-buy {
    padding: 15px;
    border-radius: 15px;
}

#product-detail .row-detail-product{
    margin: 0 2vh !important;
    justify-content: center;
}

#product-detail .detail-info {
    margin: 5px 0px;
}

#product-detail .row-valores-lote{
    margin: 10px 0vh;
    text-align: center;
}

#product-detail .row .vl {
    margin: 10px -13px 30px;
}

#product-detail .row .vl b {
    font-size: 20px;
    color: #faa00a;
}

#product-detail .row .detail-buy .btn-cancel {
    color: #faa00a !important;
}

#product-detail .row .detail-buy .btn-cancel:hover {
    background-color: #faa00a !important;
    color: white !important;
}

#product-detail .row .detail-buy .btn-create:hover {
    background-color: white !important;
    color: #faa00a !important;
}

#product-detail .row .detail-buy .col-4 {
    padding: 0;
}

@media (max-width: 1200px){

    #product-detail .row .detail-buy .btn-cancel, #product-detail .row .detail-buy .btn-create {
        margin: 10px 0;
        width: 100%;

    }
  
  }

#product-detail .row .detail-buy .detail-info .col-4 svg {
    color: #faa00a;

}


#product-detail .icon-quantidade{
    color: #faa00a;
    margin-bottom: -0.5rem;
    margin-top: 1.5vh;
}

#product-detail .row .detail-buy .col-8 {
    padding: 0 0 0 4px;

}

#product-detail .row .detail-buy .detail-info b {
    font-size: 15px;
}

#product-detail .row .detail-buy .detail-info p {
    font-size: 14px;
}

#product-detail #nav-product-detail-social-custom {
    margin-left: -15px;

}
#product-detail #nav-product-detail-social-custom a{
    display: block;
    padding: .5rem 1rem;
}

#product-detail #nav-product-detail-social-custom .icon-WhatsApp {
    color: #4caf50;
}

#product-detail #nav-product-detail-social-custom .icon-Facebook {
    color: #3578e5;
}

#product-detail #nav-product-detail-social-custom .icon-Instagram {
    color: #e775aa;
}


#product-detail .data-sheet{
    border-radius: 15px;
    padding: 20px;
}

#product-detail .data-sheet p, #product-detail .data-sheet b{
    margin-top: 10px;
}

#product-detail .data-sheet .colum-light-gray{
    margin-top: 20px;
    height: 50px;
    background-color: #f7f7f7;
}

#product-detail .data-sheet .colum-white{
    margin-top: 20px;
    height: 50px;
    background-color: #fff;
}


#product-detail .react-share__ShareButton {
    margin-left: 10px;
}

/* Section product similar*/

#product-similar .BrainhubCarousel__arrowRight {
    right: 20px;
    border: 1px solid #d6d4d430;
    border-radius: 50px;
    color: #000;
    font-size: 0;
    background-color: #ffffff;
    z-index: 25;
}

#product-similar .BrainhubCarousel__arrowLeft {
    left: 20px;
    border: 1px solid #d6d4d430;
    border-radius: 50px;
    color: #000;
    font-size: 0;
    background-color: #ffffff;
    z-index: 25;
}
#product-similar .BrainhubCarousel__arrows span {
    border-color: #000;
}

#product-similar .card {
    box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.15);
    margin: 8px;
    border-radius: 25px;
}

/* #product-similar img {
    margin: 0 0vh;
    height: 180px;
    object-fit: cover;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
} */

#product-similar .card .card-body {
    height: 200px;
}

#product-similar .card .card-title {
    font-weight: 600;
    height: 40px;
}

#product-similar .card  .card-text {
    color: rgb(103, 96, 96);
    height: 50px;
}

#product-similar .text-ticket {
    color: #faa00a;
}

#product-similar .text-value {
    color: #faa00a;
    margin-top: 6vh;
    font-weight: 800;
    font-size: 1.5rem;
    text-align: center;
}

#product-similar a {
    text-decoration: none;
    color: inherit;
}
/* Register */

#login-bg {
    background-image: url(/static/media/bg-login-3840.dd4cc7ec.jpg);
	background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display:flex;
    display: -webkit-flex; /* Garante compatibilidade com navegador Safari. */
    justify-content: center;
    align-items: center;
}

@media (max-width: 360px){
    #login-bg {
        background-image: url(/static/media/bg-login-1920.57789b55.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100vh;
        display:flex;
        display: -webkit-flex; /* Garante compatibilidade com navegador Safari. */
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 660px){
    #login-bg {
        background-image: url(/static/media/bg-login-1920.57789b55.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100vh;
        display:flex;
        display: -webkit-flex; /* Garante compatibilidade com navegador Safari. */
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 767px){
    #login-bg {
        background-image: url(/static/media/bg-login-3840.dd4cc7ec.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100vh;
        display:flex;
        display: -webkit-flex; /* Garante compatibilidade com navegador Safari. */
        justify-content: center;
        align-items: center;
    }
}

#login-bg:after {
    content:"";
    background:rgba(27, 26, 26, 0.4);
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    z-index: 1;
}

#login-user {
    width: 100%;
    max-width: 380px;
    z-index: 2;
    margin-top: -100px;
}

#login-user .bg-clear-custom {
    background-color: rgba(255, 255, 255, 0)!important;
    margin-top: 20px;
    border-radius: 25px;
    border-radius: 25px;
    width: 100%;
    max-width: 380px;
    margin-left: 0;
}

#login-user .login-user-form{
    margin-top: 25px;
}

#login-user .login-user-form form {
    margin-top: 20px;
    padding: 0 0px;
    justify-self: center;
}

#login-user .login-user-form form .form-control {
    color: black;
    background-color: white;
    border: 1px solid #ffffff;
    border-radius: .25rem!important;
}


#login-user .login-user-form form ::-webkit-input-placeholder {
    color: #d3cdcb!important;
 }
 
 #login-user .login-user-form form :-moz-placeholder {
    color: #d3cdcb!important;
 }
 
 #login-user .login-user-form form ::-moz-placeholder {
    color: #d3cdcb!important;
 }
 
 #login-user .login-user-form form :-ms-input-placeholder {  
    color: #d3cdcb!important;
 }

#login-user .login-user-form form .row input {
    margin-left: -3px;
}

#login-user .login-user-form form .form-group input {
    margin-top: 5px;
    border-radius: 25px;
}

#login-user .login-user-form form .col-forgot{
    margin-top: 15px;
}

#login-user .login-user-form form .btn-forgot {
    font-size: 14px;
    color: #ed980e;
    margin-top: 20px;
}

#login-user .login-user-form form button {
    margin-top: 10px;
}

#login-user .login-user-form form .btn-create {
    width: 100%;
}

#login-user .login-user-form .btn-link {
    width: auto;
}

#login-user #other-login {
    margin-top: 10px;
}
#login-user #other-login p{
    color: rgb(167, 157, 157);
}

#login-user #other-login a{
    margin: 0 20px;
}

#login-user #other-login .google{
    color: #DD4B39;
}

#login-user #other-login a .facebook{
    color: #3578e5;
}

#login-user #other-login a .instagram{
    color: #ad2663;
}

#login-user #no-register {
    margin-top: 20px;
}

#login-user #no-register p{
    color: rgb(167, 157, 157);
}

#login-user #no-register .btn-no-register {
    font-size: 25px;
    color: #faa00a!important;
}

#login-user #no-register .btn-register {
    width: 200px;
}






#list-auction button, #list-auction a{
    color: #fff;
    background-color: #fd880d;
    border-color: #fd880d;
}

#list-auction .btn-cadastro-auction {
    margin: 5vh 0vh;
}

#list-auction .btn-cadastro-auction:focus,:active {
    border-color: #fd880d;
    box-shadow: none !important;
}

#list-auction .card .card-img-top {
    height: 250px;
    object-fit: cover;
}

#list-auction .rc-pagination button, #list-auction .rc-pagination a{
    color: rgb(0, 0, 0);
    background-color: #ffffff;
    border-color: #ddd0d0;
}
#auction-detail {
    margin-top: 30px;
}

#auction-detail .col-12 {
    padding-right: 0px;
}

#auction-detail .menu-left {
    padding: 0px 20px 0px 0px;

}

#auction-detail #side-info-auction {
    padding-left: 0px;
}

#auction-detail #side-info-auction h3 {
    padding-bottom: 25px;
}

#auction-detail .menu-left .title-custom-h2{
    margin-left: -15px;
}

#auction-detail .menu-left p{
    margin-top: -22px;
    font-size: 13px;
    color: #676060;
}

#auction-detail .menu-left h5 {
    margin: 13px 0;
}

#auction-detail .menu-left .form-group {
    width: 100%;
}

#auction-detail .menu-left .form-group .row {
    margin: 0;
}

#auction-detail .menu-left .form-group .row  input {
    margin: 0 5px 0 0;
}

#auction-detail .menu-left .form-group .row .rc-slider {
    margin-top: 20px;
}


#auction-detail .menu-left .form-group .accordion .card {
    border-radius: 15px;
    border: none;
}

#auction-detail .menu-left .form-group .accordion .card .card-header {
    background-color: #fff;
    border-bottom: none;
    padding: .75rem 0.25rem;
}

#auction-detail .menu-left .form-group .accordion .card .card-header button{
    width: 100%;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
}

#auction-detail .menu-left .form-group .accordion .card .card-header button b b{
    float: right;
}

#auction-detail .menu-left .form-group .accordion .card .collapse .card-body input, #auction-detail .menu-left .form-group .accordion .card .collapse .card-body select{
    border-radius: 15px;
}

#auction-detail .colunm {
    padding: 10px 10px;
    border-radius: 15px;
}

#auction-detail .item-product {
    margin-right: 0px;
    margin-left: 0px;
    margin: 10px 0;

}

#auction-detail .colunm .col-12, #auction-detail .colunm .col-sm-3, #auction-detail .colunm .col-sm-9 {
    padding-right: 0px;
    padding-left: 0px;
}

#auction-detail .list-right h3 {
    margin: 25px 10px;
}

#auction-detail .list-right figure {
    margin: 0;
    min-width: 100%;
}

#auction-detail .list-right figure .figure-img {
    margin-bottom: 0;
    border-radius: 15px;
    width: 100%;
    height: 26vh;
    max-height: 32vh;
    object-fit: cover;
}

@media (max-width: 991px) {
    #auction-detail .list-right figure .figure-img {
        height: auto;
    }
}


#auction-detail .list-right .info-lots{
    padding-right: 15px;
    padding-left: 15px;
}

#auction-detail .list-right .info-lots .titles h2{
    margin: 10px 10px;
    font-weight: 600;
    font-size: 1.5rem;
}

@media (max-width: 991px) {
    #auction-detail .list-right .info-lots .titles h2{
        font-size: 1.5rem;
    }
}

#auction-detail .list-right .info-lots .titles {
    justify-content: space-between;
}

#auction-detail .list-right .info-lots .titles p{
    margin: 10px 3px;
    background-color:#faa00a;
    float: right;
    padding: 5px 7px;
    font-size: 13px;
    border-radius: 25px;
    color: #fff;
    display:flex;
    justify-content:right
}

@media (max-width: 991px) {
    #auction-detail .list-right .info-lots .titles p{
        padding: 7px 15px;
        margin: 0px 10px 10px 10px;
        font-size: 10px;
    }
}

#auction-detail .list-right .info-lots .description p{
    color: #676060;
    text-align: left;
}

#auction-detail .list-right .info-lots .vl-show-details {
    width: 100%;
    margin-top: 50px;
}
@media (max-width: 991px) {
    #auction-detail .list-right .info-lots .vl-show-details {
        width: 100%;
        margin-top: 10px;
    }
}

#auction-detail .list-right .info-lots .vl-show-details .type-coin {
    width: 100%;
    margin: 0px 0px -7px 30px;
    color: #faa00a;
}

@media (max-width: 768px) {
    #auction-detail .list-right .info-lots .vl-show-details .type-coin {
        width: 100%;
        margin: 0px 70px -7px 0px;
        color: #faa00a;
    }
}

#auction-detail .list-right .info-lots .vl-show-details .vl {
    margin-left: 15px;
    font-weight: 600;
    color: #faa00a;
}

@media (max-width: 991px) {
    #auction-detail .list-right .info-lots .vl-show-details .vl {
        font-size: 1.3rem;
    }
}

#auction-detail .list-right .info-lots .vl-show-details a {
    margin: 0px;
    padding: 0.55rem .75rem;
    width: 150px;
}

@media (max-width: 991px) {
    #auction-detail .list-right a {
        margin: 0px;
        width: 100%;
    }
}

#auction-detail .card .card-img-top {
    height: 200px;
    object-fit: cover;
}

#auction-detail .register-lote {
    padding: 15px 0;
}


#auction-detail .register-lote .btn-create{
    width: auto;
    margin: 10px;
}

#auction-detail .text-finish-auction {
    background: #ececec;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    padding: 20px;
    color:rgb(0, 0, 0);
    border-radius: 15px;
}

.numbers {
    font-size: 1rem;
    font-weight: 700;
    height: 45px;
    background-color: whitesmoke;
    border-radius: 15px;
    padding-top:0.5rem
}

.chars {
    font-size: 14px;
}
p,
b {
    color: #686464;
}

#product-detail-auction {
    padding-top: 30px;    
}

@media screen and (min-width: 1297px) {
    #product-detail-auction {
        padding-top: 60px;
    }
}

@media screen and (min-width: 1381px) {
    #product-detail-auction {
        padding-top: 30px;
    }
}

#product-detail-auction .row h2 {
    font-weight: 700;
    margin-top: 30px;
}

#product-detail-auction .call-to-action-detail {
    width: 100%;
    margin: auto;
    height: 3vh;
    margin-left: -1vw;
    margin-top:1vh;
}

#product-detail-auction .call-to-shoot {
    width: 75%;
    font-size: 30px !important;
    padding-top: 0vh !important;
    height: 36px !important;
    margin-top: 1vh !important;
    box-sizing: content-box;
}

#product-detail-auction .ultimo-btn {
    text-align: right;
}

#product-detail-auction .btns-lance {
    margin: -30px 0vw 1vh 0;
    /* margin-top: -30px; */
}

#product-detail-auction .visitors {
    /* margin-top: 5px; */
    margin-top: 10%;
    vertical-align: middle;
}

.btn-fale-conosco {
    box-sizing: content-box;
    margin: auto;
    margin-left: -2vw;
    width: 32vw;
}

.carousel .slide img {
    max-width: 100vh;
    max-height: 71vh;

    text-align: center !important;
    width: auto !important;
    height: auto !important;
}

.image-gallery-slide img {
    height: 50vh;
}

.my-slide .thumb-img-video {
    display: none !important;
}

#product-detail-auction .row-top {
    margin-bottom: 20px;
    margin-top: 15px;
}

#product-detail-auction .row p {
    margin-bottom: 30px;
}

#product-detail-auction .row-lance-atual {
    font-size: 25px;
    margin: auto;
    margin-bottom: -4vh;
}

#product-detail-auction .row-lance-atual .col-lance-atual {
    padding-left: 0 !important;
}

#product-detail-auction .row-lance-atual .titulo {
    margin-left: 1vw;
}

#product-detail-auction .row-lance-atual p {
    justify-content: center;
}

#product-detail-auction .row-lance-atual h4 {
    margin: -15px 0px 25px;
    color: white;
    padding: 2vh;
    background-color: #faa00a;
    border-radius: 30px;
}

#product-detail-auction .img-placeholder {
    overflow: hidden;
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: #f8f8f8;
}

#product-detail-auction .img-placeholder img {
    margin: 0px 0px 0px -8rem;
    max-width: 100vh;
    max-height: 71vh;
    text-align: center!important;
    width: auto!important;
    height: auto!important;
}

#product-detail-auction .row .image-gallery .image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
    border-radius: 15px;
}

#product-detail-auction .row .image-gallery .image-gallery-thumbnail .image-gallery-thumbnail-image {
    border-radius: 10px;
}

#product-detail-auction .row .image-gallery .image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
    border-radius: 15px;
}

#product-detail-auction .row .image-gallery .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 40px;
    width: 40px;
}

#product-detail-auction .row .detail-buy {
    padding: 15px;
    border-radius: 15px;
}

#product-detail-auction .row-detail-product {
    margin: -1vh 1vw -5vh 1vw!important;
    justify-content: center;
}

#product-detail-auction .detail-info {
    margin: 5px 0px;
}


#product-detail-auction .col-detalhes {
    margin-top: -15vh;
}


@media (max-width: 992px) {
    #product-detail-auction .col-detalhes {
        margin-top: 0vh;
    }
}

#product-detail-auction .row-valores-lote {
    margin: 4px 0vh;
    text-align: center;
    /* background-color:#9c9c9c30; */
    /* border-radius: 25px; */
}

#product-detail-auction .destaque {
    background-color:#9c9c9c30;
    padding: 10px;
    padding-left: 0;
    margin-bottom: 10px;
    border-radius: 25px;
}

#product-detail-auction .row-valores-lote div {
    margin-top: 0.8rem;
}

#product-detail-auction .row .vl {
    margin: 10px -13px 30px;
    font-size: 1.2rem;
}

#product-detail-auction .row .vl b {
    color: #faa00a;
}

#product-detail-auction .row .detail-buy .btn-cancel {
    color: #faa00a !important;
}

#product-detail-auction .row .detail-buy .btn-cancel:hover {
    background-color: #faa00a !important;
    color: white !important;
}

#product-detail-auction .row .detail-buy .btn-create:hover {
    background-color: white !important;
    color: #faa00a !important;
}

#product-detail-auction .row .detail-buy .col-4 {
    padding: 0;
}

@media (max-width: 1200px) {

    #product-detail-auction .row .detail-buy .btn-cancel,
    #product-detail-auction .row .detail-buy .btn-create {
        margin: 10px 0;
        width: 100%;

    }

}

#product-detail-auction .row .detail-buy .detail-info .col-4 svg {
    color: #faa00a;

}


#product-detail-auction .icon-quantidade {
    color: #faa00a;
    margin-bottom: -0.5rem;
    margin-top: 1.5vh;
}

#product-detail-auction .row .detail-buy .col-8 {
    padding: 0 0 0 1vw;

}

#product-detail-auction .row .detail-buy .detail-info b {
    font-size: 15px;
}

#product-detail-auction .row .detail-buy .detail-info p {
    font-size: 14px;
}

#product-detail-auction #nav-product-detail-social-custom {
    margin-left: -15px;

}

#product-detail-auction #nav-product-detail-social-custom a {
    display: block;
    padding: .5rem 1rem;
}

#product-detail-auction #nav-product-detail-social-custom .icon-WhatsApp {
    color: #4caf50;
}

#product-detail-auction #nav-product-detail-social-custom .icon-Facebook {
    color: #3578e5;
}

#product-detail-auction #nav-product-detail-social-custom .icon-Instagram {
    color: #e775aa;
}


#product-detail-auction .data-sheet {
    border-radius: 15px;
    padding: 20px;
}

#product-detail-auction .data-sheet p,
#product-detail-auction .data-sheet b {
    margin-top: 10px;
}

#product-detail-auction .data-sheet .colum-light-gray {
    margin-top: 20px;
    height: 50px;
    background-color: #f7f7f7;
}

#product-detail-auction .data-sheet .colum-white {
    margin-top: 20px;
    height: 50px;
    background-color: #fff;
}


/* Section product similar*/

#product-similar .BrainhubCarousel__arrowRight {
    right: 20px;
    border: 1px solid #d6d4d430;
    border-radius: 50px;
    color: #000;
    font-size: 0;
    background-color: #ffffff;
    z-index: 25;
}

#product-similar .BrainhubCarousel__arrowLeft {
    left: 20px;
    border: 1px solid #d6d4d430;
    border-radius: 50px;
    color: #000;
    font-size: 0;
    background-color: #ffffff;
    z-index: 25;
}

#product-similar .BrainhubCarousel__arrows span {
    border-color: #000;
}

#product-similar .card {
    box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.15);
    margin: 8px;
    border-radius: 25px;
}

/* #product-similar img {
    margin: 0 0vh;
    height: 180px;
    object-fit: cover;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
} */

#product-similar .card .card-body {
    height: 200px;
}

#product-similar .card .card-title {
    font-weight: 600;
    height: 40px;
}

#product-similar .card .card-text {
    color: rgb(103, 96, 96);
    height: 50px;
}

#product-similar .text-ticket {
    color: #faa00a;
}

#product-similar .text-value {
    color: #faa00a;
    margin-top: 6vh;
    font-weight: 800;
    font-size: 1.5rem;
    text-align: center;
}

#product-similar a {
    text-decoration: none;
    color: inherit;
}

#product-detail-auction .text-finish-auction {
    background: #ececec;
    height: 80px;
    font-weight: 700;
    text-align: center;
    padding: 20px;
    color:rgb(103, 96, 96);
    border-radius: 15px;
}

@media (max-width: 1200px) {

    #product-detail-auction .text-finish-auction {
        font-size: 2rem;
    }

}
/* Register */

#register-user .bg-clear-custom {
    /* background: #000; */
    margin-top: 20px;
    border-radius: 25px;
    box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
}

#register-user .img-gado-register-user {
    padding-left: 0;
    padding-right: 0;

}

#register-user .img-gado-register-user .figure {
    margin: 0;
    width: 100%;
}

#register-user .img-gado-register-user img {
    margin-bottom: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;

}

#register-user .register-user-form{
    margin-top: 25px;
}

#register-user .register-user-form .progress-register-user {
    padding: 0 40px;
}

#register-user .register-user-form .progress-register-user p {
    color: rgb(103, 96, 96, 0.78);
    font-size: 13px;
    text-transform: uppercase;
}

#register-user .register-user-form .progress-register-user .progress {
    height: .4rem;
}

#register-user .register-user-form form {
    margin-top: 20px;
    padding: 0 40px;
    justify-self: start;

}

#register-user .register-user-form form .row {
    margin-left: 0;
    margin-right: 0;
}

#register-user .register-user-form form .row .col-sm-6, #register-user .register-user-form form .row .col-sm-9, #register-user .register-user-form form .row  .col-sm-3  {
    padding-left: 0;
    padding-right: 0;

}

#register-user .register-user-form form .row input {
    margin-left: -3px;
}

#register-user .register-user-form form .form-group input,  #register-user .register-user-form form .form-group select {
    margin-top: 5px;
    border-radius: 25px;
}

#register-user .register-user-form form .form-group .form-check {
    float: left;
}

#register-user .register-user-form form .form-group .label-dt-brith {
    float: left;
    font-size: 14px;
    margin: 0px 0px -2px 15px;
    color: #6c79a5;
}

#register-user .register-user-form form .form-group .label-control {
    float: left;
    font-size: 14px;
    margin: 0px 0px -2px 15px;
    color: #6c79a5;
}

#register-user .register-user-form form button {
    margin: 40px 0;
}

#register-user .register-user-form form .btn-create {
    width: 100%;
}

#register-user #data-confirmation-user {
    padding: 0 40px;
}

#register-user #data-confirmation-user p {
    color: #8d9092;
    margin: 20px 0 -10px 0px;
}

#register-user #data-confirmation-user p svg {
    margin-right: 20px;
    color: #8d9092;
}

#register-user form .form-group label a {
    color: #faa00a;
    font-weight: 600;
}

#register-user form .form-group label a {
    color: #faa00a;
    font-weight: 600;
}

#termsCompromisse {
    margin: 8vh 0vh;
}

#termsCompromisse h3{
    text-align: center;
    margin-bottom: 5vh;
}

@media (max-width: 920px){
    #termsCompromisse p {
        text-align: justify;
        margin: 0vh 6vh;
    }

}


@media (max-width: 640px){
    #termsCompromisse p {
        text-align: justify;
        margin: 0vh 6vh;
    }

}

@media (max-width: 360px){
    #termsCompromisse p {
        text-align: justify;
        margin: 0vh 6vh;
    }

}

#termsCompromisse p {
    text-align: justify;
    margin: 3vh 10vh;
}

#termsCompromisse .row{
    justify-content: center;
}

#termsCompromisse .btn-voltar{
    width: 80vh;
}

#termsCompromisse .assinatura{
    text-align: right;
    margin-bottom: 5vh;

}
/* Register */

#forgot-password-user .bg-clear-custom {
    background: #000;
    margin-top: 20px;
    border-radius: 25px;
    box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    width: 100%;
    max-width: 500px;
}

#forgot-password-user .forgot-password-user-form{
    margin-top: 25px;
}

#forgot-password-user .forgot-password-user-form form {
    margin-top: 20px;
    padding: 0 40px;
    justify-self: start;
}

#forgot-password-user .forgot-password-user-form form .row input {
    margin-left: -3px;
}

#forgot-password-user .forgot-password-user-form form .form-group input {
    margin-top: 5px;
    border-radius: 25px;
}

#forgot-password-user .forgot-password-user-form form button {
    margin: 40px 0;
}

#forgot-password-user .forgot-password-user-form form .btn-create {
    width: 100%;
}

#forgot-password-user .msg-success h3 {
    color:  #5e7b06;
    font-weight: 600;
}

#forgot-password-user .msg-success h5 {
    color:  #676060;
    font-weight: 600;
    margin-bottom: 50px;

}


#user a {
    text-decoration:none; 
}

#user .bg-transparent-custom {
    margin-top: 50px;
}

#user .bg-transparent-custom h5 {
    margin: 30px 0; 
}

#user .bg-transparent-custom .card-body {
    border-radius: 15px!important;
}

#user .bg-transparent-custom .card-body:hover {
    border: 1px solid  #faa413!important;
    transition: all 0.2s;
}

#user .bg-transparent-custom .card-body svg, #user .bg-transparent-custom .card-body .figure {
    color:  #faa413!important;
    margin: 25px 0;
}

#user .bg-transparent-custom .card-body .figure {
    margin: 22px 0;
}

#user .bg-transparent-custom .card-body .card-title {
    color:  #3c4045!important;
    margin-bottom: 25px;
}
#user-update-data {
    margin-top: 20px;
}

#user-update-data #upload-foto-profile {
    cursor: pointer;
}

/* #user-update-data .link-update-photo{
    color: white !important; 
    background-color:#FAA00A !important;
    padding: 10px !important;
    margin-top: 10px;
    border-radius: 20px !important;
} */

#user-update-data #upload-foto-profile input[type="file"] {
    display: none;
}

#user-update-data #upload-foto-profile p {
    color: #686464;
}

#user-update-data #upload-foto-profile p svg {
    margin-right: 5px;
}

#user-update-data .forms {
    border-radius: 15px;
}

#user-update-data .forms .figure { 
    width: 150px;
    height: 150px;
}

#user-update-data .forms .figure img {
    margin-top: 15px;
    background: no-repeat center;
    background-size: cover;    
    width: 150px;
    height: 150px;
}

#user-update-data .forms button {
    color: #686464;
}

#user-update-data .forms form {
    margin: 20px 0;
}

#user-update-data .forms form input, #user-update-data .forms form select {
    border-radius: 25px;
}

#user-update-data .forms form .form-group .label-dt-brith {
    float: left;
    font-size: 14px;
    margin: 0px 0px -2px 15px;
    color: #6c79a5;
}

#user-update-data .forms form .form-group .form-check {
    float: left;
    margin: 10px 15px 0px 0
}

#user-update-data .forms form .update-address {
    margin-bottom: 70px;
}

#user-update-data .forms form .link-update-password {
    display: inline-block;
    padding: 1rem 0rem;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
}

#user-update-data .forms form .btn-create {
    margin-right: 10px;
}

#user-update-data .link-delete-user {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    background-color: #fff0!important;
    border-color: #fff0!important;
    color: #686464;
}

#upload-foto-profile-input {
    color: white !important; 
    background-color:#FAA00A !important;
    padding: 10px !important;
    border-radius: 20px !important;
}

#upload-foto-profile-button {
    color: white !important; 
    background-color:#FAA00A !important;
    border-color: white;
    padding: 10px !important;
    border-radius: 20px !important;
}

#update-password-user .bg-clear-custom {
    background: #000;
    margin-top: 20px;
    border-radius: 25px;
    box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    width: 100%;
    max-width: 500px;
}

#update-password-user .update-password-user-form{
    margin-top: 25px;
}

#update-password-user .update-password-user-form form {
    margin-top: 20px;
    padding: 0 40px;
    justify-self: start;
}


#update-password-user .update-password-user-form form .form-group input {
    margin-top: 5px;
    border-radius: 25px;
}

#update-password-user .update-password-user-form form .buttons {
    margin-bottom: 40px;
}

#update-password-user .update-password-user-form form .btn-create {
    margin-right: 10px;
}

#lots-sales-user .col-12 {
    padding-right: 0px;
}


#lots-sales-user .menu-left p{
    margin-top: -22px;
    font-size: 13px;
    color: #676060;
}

#lots-sales-user .colunm {
    border-radius: 15px;
}

#lots-sales-user .colunm .col-12, #lots-sales-user .colunm .col-sm-3, #lots-sales-user .colunm .col-sm-9 {
    padding-right: 0px;
    padding-left: 0px;
}

@media (max-width: 576px) {
    #lots-sales-user .list-right figure {
        padding: 20px 55px;
    }
}


#lots-sales-user .list-right figure {
    margin: 0;
    min-width: 100%;
}

#lots-sales-user .list-right figure .figure-img {
    margin-bottom: 0;
    border-radius: 15px;
    width: 100%;
    height: 200px;
}


#lots-sales-user .list-right .info-lots{
    padding-right: 15px;
    padding-left: 15px;
}

#lots-sales-user .list-right .info-lots .titles h3{
    margin: 10px 10px;
    font-weight: 600;
}

#lots-sales-user .list-right .info-lots .titles p{
    margin: 10px 5px;
    background-color: #9e9e9e;
    padding: 7px 15px;
    font-size: 13px;
    border-radius: 25px;
    color: #fff;
}

#lots-sales-user .list-right .info-lots .description p{
    color: #676060;
}

#lots-sales-user .list-right .info-lots .vl-show-details {
    width: 100%;
    margin-top: 50px;
}

#lots-sales-user .list-right .info-lots .vl-show-details .type-coin {
    width: 100%;
    margin: 0px 0px -7px 30px;
    color: #faa00a;
}

@media (max-width: 768px) {
    #lots-sales-user .list-right .info-lots .vl-show-details .type-coin {
        width: 100%;
        margin: 0px 70px -7px 0px;
        color: #faa00a;
    }
}

#lots-sales-user .list-right .info-lots .vl-show-details .vl {
    margin-left: 15px;
    font-weight: 600;
    color: #faa00a;
}

@media (max-width: 1999px) {
    #lots-sales-user .list-right .info-lots .vl-show-details a {
        width: auto!important;
    }
}

@media (max-width: 992px) {
    #lots-sales-user .list-right .info-lots .vl-show-details a {
        width: 100%!important;
    }
}

#lots-sales-user .list-right .info-lots .vl-show-details a {
    margin: 0px 0 10px 0;
    padding: 0.55rem .75rem;
    width: 100%;
}
#request-user .col-12 {
    padding-right: 0px;
}

#request-user .row .nav-top {
    margin-bottom: 20px;
}

#request-user .menu-left p{
    margin-top: -22px;
    font-size: 13px;
    color: #676060;
}

#request-user .colunm {
    border-radius: 15px;
}

#request-user .colunm .col-12, #request-user .colunm .col-sm-3, #request-user .colunm .col-sm-9 {
    padding-right: 0px;
    padding-left: 0px;
}

@media (max-width: 576px) {
    #request-user .list-right figure {
        padding: 20px 55px;
    }
}

#request-user .list-right figure {
    margin: 0;
}

#request-user .list-right figure .figure-img {
    margin-bottom: 0;
    border-radius: 15px;
    width: 100%;
    height: 200px;
}

#request-user .list-right .info-lots{
    padding-right: 15px;
    padding-left: 15px;
}

#request-user .list-right .info-lots .description{
    margin: 0 10px 0 10px;
}

#request-user .list-right .info-lots .titles h3{
    margin: 10px 10px;
    font-weight: 600;
}

#request-user .list-right .info-lots .table thead th {
    border-bottom: none;
}

#request-user .list-right .info-lots .table td, #request-user .list-right .info-lots .table th {
    border-top: none;
}

#request-user .list-right .info-lots .vl-show-details {
    width: 100%;
    margin:  15px 0 10px 0px; 
    padding: 0px 0 10px 0;
}


#request-user .list-right .info-lots .vl-show-details a {
    margin: 0px;
    padding: 0.55rem .75rem;
    width: 100%;
}


#request-user-negotiation .col-12 {
    padding-right: 0px;
}

#request-user-negotiation .row .nav-top {
    margin-bottom: 20px;
}

#request-user-negotiation .menu-left p{
    margin-top: -22px;
    font-size: 13px;
    color: #676060;
}

#request-user-negotiation .colunm {
    border-radius: 15px;
}

#request-user-negotiation .colunm .col-12, #request-user-negotiation .colunm .col-sm-3, #request-user-negotiation .colunm .col-sm-9 {
    padding-right: 0px;
    padding-left: 0px;
}

@media (max-width: 576px) {
    #request-user-negotiation .list-right figure {
        padding: 20px 55px;
    }
}

#request-user-negotiation .list-right figure {
    margin: 0;
}

#request-user-negotiation .list-right figure .figure-img {
    margin-bottom: 0;
    border-radius: 15px;
    width: 100%;
    height: 200px;
}

#request-user-negotiation .list-right .info-lots{
    padding-right: 15px;
    padding-left: 15px;
}

#request-user-negotiation .list-right .info-lots .description{
    margin: 0 10px 0 10px;
}

#request-user-negotiation .list-right .info-lots .titles h3{
    margin: 10px 10px;
    font-weight: 600;
}

#request-user-negotiation .list-right .info-lots .table thead th {
    border-bottom: none;
}

#request-user-negotiation .list-right .info-lots .table td, #request-user-negotiation .list-right .info-lots .table th {
    border-top: none;
}

#request-user-negotiation .list-right .info-lots .vl-show-details {
    width: 100%;
    margin:  15px 0 10px 0px; 
    padding: 0px 0 10px 0;
}


#request-user-negotiation .list-right .info-lots .vl-show-details a {
    margin: 0px;
    padding: 0.55rem .75rem;
    width: 100%;
}


p, b {
    color: #686464;
}

#request-detail-user #detail_status {
    border-radius: 15px;
    padding: 20px 0;
}

#request-detail-user #detail_status .progress {
    margin-top: -25px;
    height: 4px;
}

#request-detail-user .text-progress {
    display: flex;
}

#request-detail-user .text-progress, #request-detail-user .text-progress-status {
    padding-top: 20px;
    display: flex;
}


#request-detail-user #detail_status .icon-progress {
    z-index: 10000;
    position: relative;
}

#request-detail-user #detail_status .icon-progress .icon-1, #request-detail-user #detail_status .icon-progress .icon-2, #request-detail-user #detail_status .icon-progress .icon-3{
    margin-right: 24%;
}

#request-detail-user #detail_status .icon-progress .icon-4{
    margin-left: 10px;
}

#request-detail-user #detail_status .icon-progress .icon-1{
    margin-left: -6px;
}

#request-detail-user #detail_status .icon-progress .cicle-icon-1{
    background: #c7c7c7;
    width: 40px;
    height: 40px;
    padding-top: 9px;
    color: #fff;
    border-radius: 40px;
    text-align: center;
    margin-top: 5px;
    margin-left: 13px;
}

#request-detail-user #detail_status .icon-progress .cicle-icon-extra-color{
    background: #faa00a;

}

#request-detail-user #detail_status .text-progress #text-1 {
    margin-left: 5%;
}

#request-detail-user #detail_status .text-progress #text-2{
    margin-left: 9%;
}

#request-detail-user #detail_status .text-progress #text-3{
    margin-left: 10%;
}

#request-detail-user #detail_status .text-progress #text-4{
    margin-left: 10%;
}

#request-detail-user #detail_status .text-progress-status #text-status-1{
    margin-left: 13%;
}
#request-detail-user #detail_status .text-progress-status #text-status-2{
    margin-left: 8%;
}
#request-detail-user #detail_status .text-progress-status #text-status-3{
    margin-left: 5%;
}
#request-detail-user #detail_status .text-progress-status #text-status-4{
    margin-left: 4%;
}

@media (max-width: 1200px) {
    #request-detail-user #detail_status .icon-progress .icon-1, #request-detail-user #detail_status .icon-progress .icon-2, #request-detail-user #detail_status .icon-progress .icon-3 {
        margin-right: 22%!important;
    }

    #request-detail-user .text-progress, #request-detail-user .text-progress-status {
        font-size: 14px;
    }

    #request-detail-user #detail_status .text-progress-status #text-status-1 {
        margin-left: 11%;
    }

    #request-detail-user #detail_status .text-progress-status #text-status-2 {
        margin-left: 8%;
    }

    #request-detail-user #detail_status .text-progress-status #text-status-3 {
        margin-left: 4%;
    }

    #request-detail-user #detail_status .text-progress-status #text-status-4 {
        margin-left: 2%;
    }
}


@media (max-width: 991px) {
    #request-detail-user #detail_status .progress-bar-vertical {
        width: 5px;
        min-height: 300px;
        display: flex;
        align-items: flex-start;
        margin-right: 20px;
        float: left;
      }
    
    #request-detail-user #detail_status .progress-bar-vertical .progress-bar {
        width: 100%;
        height: 0;
        transition: height 0.6s ease;
    }

    #request-detail-user #detail_status  .bar-progress-vertical .progress {
        margin-top: 0;
    }

    #request-detail-user #detail_status .bar-progress-vertical {
        margin-top: 30px;
    }

    #request-detail-user #detail_status .bar-progress-vertical .icon-progress {
        margin-right: -26px;
        margin-top: 35px;
        display: grid;
    }

    #request-detail-user #detail_status .bar-progress-vertical .icon-progress .icon-vertical-1 {
        margin-top: -50px;
    }

    #request-detail-user #detail_status .bar-progress-vertical .icon-progress .icon-vertical-2 {
        margin-top: 40px;
    }

    #request-detail-user #detail_status .bar-progress-vertical .icon-progress .icon-vertical-3 {
        margin-top: 50px;
    }

    #request-detail-user #detail_status .bar-progress-vertical .icon-progress .icon-vertical-4 {
        margin-top: 50px;
    }

    #request-detail-user #detail_status .bar-progress-vertical .icon-progress .cicle-icon-1 {
        margin-left: 6px;
    }

    #request-detail-user #detail_status .bar-progress-vertical #text-vertical-status-1 {
        margin-top: 0px;
    }

    #request-detail-user #detail_status .bar-progress-vertical #text-vertical-status-2, #request-detail-user #detail_status .bar-progress-vertical #text-vertical-status-3, #request-detail-user #detail_status .bar-progress-vertical #text-vertical-status-4 {
        margin-top: 78px;
    }
}



#request-detail-user #detail_status .product-info {
    margin: 50px 0 0 10px;
}

#request-detail-user #detail_status .btn-create {
    margin: 0px 0 0 10px;
    width: auto;
    height: auto;
}

#request-detail-user .data-sheet{
    border-radius: 15px;
    padding: 20px;
}

#request-detail-user .data-sheet p, #request-detail-user .data-sheet b{
    margin-top: 10px;
}

#request-detail-user .data-sheet .colum-light-gray{
    margin-top: 20px;
    height: 50px;
    background-color: #f7f7f7;
}

#request-detail-user .data-sheet .colum-white{
    margin-top: 20px;
    height: 50px;
    background-color: #fff;
}

#request-detail-user #media-product .photo-view {
    margin: 10px;
    border-radius: 15px;
    width: 150px;
    height: 150px;
}

@media (max-width: 510px) {
    #request-detail-user #media-product .photo-view {
        margin: 10px;
        border-radius: 15px;
        width: 220px;
        height: 180px;
    }
}


@media (max-width: 490px) {
    #request-detail-user #media-product .photo-view {
        margin: 10px;
        border-radius: 15px;
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 400px) {
    #request-detail-user #media-product .photo-view {
        margin: 10px;
        border-radius: 15px;
        width: 175px;
        height: 150px;
    }
}


#request-detail-user #media-product .photo-view img {
    border-radius: 15px;
}

p, b {
    color: #686464;
}

#request-detail-user-negotiation #detail_status {
    border-radius: 15px;
    padding: 20px 0;
}

#request-detail-user-negotiation #detail_status .progress {
    margin-top: -25px;
    height: 4px;
}

#request-detail-user-negotiation .text-progress {
    display: flex;
}

#request-detail-user-negotiation .text-progress, #request-detail-user-negotiation .text-progress-status {
    padding-top: 20px;
    display: flex;
}


#request-detail-user-negotiation #detail_status .icon-progress {
    z-index: 10000;
    position: relative;
}

#request-detail-user-negotiation #detail_status .icon-progress .icon-1, #request-detail-user-negotiation #detail_status .icon-progress .icon-2, #request-detail-user-negotiation #detail_status .icon-progress .icon-3{
    margin-right: 24%;
}

#request-detail-user-negotiation #detail_status .icon-progress .icon-4{
    margin-left: 10px;
}

#request-detail-user-negotiation #detail_status .icon-progress .icon-1{
    margin-left: -6px;
}

#request-detail-user-negotiation #detail_status .icon-progress .cicle-icon-1{
    background: #c7c7c7;
    width: 40px;
    height: 40px;
    padding-top: 9px;
    color: #fff;
    border-radius: 40px;
    text-align: center;
    margin-top: 5px;
    margin-left: 13px;
}

#request-detail-user-negotiation #detail_status .icon-progress .cicle-icon-extra-color{
    background: #faa00a;

}

#request-detail-user-negotiation #detail_status .text-progress #text-1 {
    margin-left: 5%;
}

#request-detail-user-negotiation #detail_status .text-progress #text-2{
    margin-left: 9%;
}

#request-detail-user-negotiation #detail_status .text-progress #text-3{
    margin-left: 10%;
}

#request-detail-user-negotiation #detail_status .text-progress #text-4{
    margin-left: 10%;
}

#request-detail-user-negotiation #detail_status .text-progress-status #text-status-1{
    margin-left: 13%;
}
#request-detail-user-negotiation #detail_status .text-progress-status #text-status-2{
    margin-left: 8%;
}
#request-detail-user-negotiation #detail_status .text-progress-status #text-status-3{
    margin-left: 5%;
}
#request-detail-user-negotiation #detail_status .text-progress-status #text-status-4{
    margin-left: 4%;
}

@media (max-width: 1200px) {
    #request-detail-user-negotiation #detail_status .icon-progress .icon-1, #request-detail-user-negotiation #detail_status .icon-progress .icon-2, #request-detail-user-negotiation #detail_status .icon-progress .icon-3 {
        margin-right: 22%!important;
    }

    #request-detail-user-negotiation .text-progress, #request-detail-user-negotiation .text-progress-status {
        font-size: 14px;
    }

    #request-detail-user-negotiation #detail_status .text-progress-status #text-status-1 {
        margin-left: 11%;
    }

    #request-detail-user-negotiation #detail_status .text-progress-status #text-status-2 {
        margin-left: 8%;
    }

    #request-detail-user-negotiation #detail_status .text-progress-status #text-status-3 {
        margin-left: 4%;
    }

    #request-detail-user-negotiation #detail_status .text-progress-status #text-status-4 {
        margin-left: 2%;
    }
}


@media (max-width: 991px) {
    #request-detail-user-negotiation #detail_status .progress-bar-vertical {
        width: 5px;
        min-height: 300px;
        display: flex;
        align-items: flex-start;
        margin-right: 20px;
        float: left;
      }
    
    #request-detail-user-negotiation #detail_status .progress-bar-vertical .progress-bar {
        width: 100%;
        height: 0;
        transition: height 0.6s ease;
    }

    #request-detail-user-negotiation #detail_status  .bar-progress-vertical .progress {
        margin-top: 0;
    }

    #request-detail-user-negotiation #detail_status .bar-progress-vertical {
        margin-top: 30px;
    }

    #request-detail-user-negotiation #detail_status .bar-progress-vertical .icon-progress {
        margin-right: -26px;
        margin-top: 35px;
        display: grid;
    }

    #request-detail-user-negotiation #detail_status .bar-progress-vertical .icon-progress .icon-vertical-1 {
        margin-top: -50px;
    }

    #request-detail-user-negotiation #detail_status .bar-progress-vertical .icon-progress .icon-vertical-2 {
        margin-top: 40px;
    }

    #request-detail-user-negotiation #detail_status .bar-progress-vertical .icon-progress .icon-vertical-3 {
        margin-top: 50px;
    }

    #request-detail-user-negotiation #detail_status .bar-progress-vertical .icon-progress .icon-vertical-4 {
        margin-top: 50px;
    }

    #request-detail-user-negotiation #detail_status .bar-progress-vertical .icon-progress .cicle-icon-1 {
        margin-left: 6px;
    }

    #request-detail-user-negotiation #detail_status .bar-progress-vertical #text-vertical-status-1 {
        margin-top: 0px;
    }

    #request-detail-user-negotiation #detail_status .bar-progress-vertical #text-vertical-status-2, #request-detail-user-negotiation #detail_status .bar-progress-vertical #text-vertical-status-3, #request-detail-user-negotiation #detail_status .bar-progress-vertical #text-vertical-status-4 {
        margin-top: 78px;
    }
}



#request-detail-user-negotiation #detail_status .product-info {
    margin: 50px 0 0 10px;
}

#request-detail-user-negotiation #detail_status .btn-create {
    margin: 0px 0 0 10px;
    width: auto;
}

#request-detail-user-negotiation .data-sheet{
    border-radius: 15px;
    padding: 20px;
}

#request-detail-user-negotiation .data-sheet p, #request-detail-user-negotiation .data-sheet b{
    margin-top: 10px;
}

#request-detail-user-negotiation .data-sheet .colum-light-gray{
    margin-top: 20px;
    height: 50px;
    background-color: #f7f7f7;
}

#request-detail-user-negotiation .data-sheet .colum-white{
    margin-top: 20px;
    height: 50px;
    background-color: #fff;
}

#request-detail-user-negotiation #media-product .photo-view {
    margin: 10px;
    border-radius: 15px;
    width: 150px;
    height: 150px;
}

@media (max-width: 510px) {
    #request-detail-user-negotiation #media-product .photo-view {
        margin: 10px;
        border-radius: 15px;
        width: 220px;
        height: 180px;
    }
}


@media (max-width: 490px) {
    #request-detail-user-negotiation #media-product .photo-view {
        margin: 10px;
        border-radius: 15px;
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 400px) {
    #request-detail-user-negotiation #media-product .photo-view {
        margin: 10px;
        border-radius: 15px;
        width: 175px;
        height: 150px;
    }
}


#request-detail-user-negotiation #media-product .photo-view img {
    border-radius: 15px;
}

p, b {
    color: #686464;
}

#lots-sales-user-detail .row h2 {
    font-weight: 700;
    margin-top: 30px;
}

.image-gallery-slide img {
    height: 50vh;
}

#lots-sales-user-detail .row-top {
    margin-bottom: 20px;
}

#lots-sales-user-detail .row p {
    margin-bottom: 30px;
}

#lots-sales-user-detail .row .image-gallery .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    border-radius: 15px;
}
#lots-sales-user-detail .row .image-gallery .image-gallery-thumbnail .image-gallery-thumbnail-image {
    border-radius: 10px;
}

#lots-sales-user-detail .row .image-gallery .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    border-radius: 15px;
}

#lots-sales-user-detail .row .image-gallery .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 40px;
    width: 40px;
}

#lots-sales-user-detail .row .detail-buy {
    padding: 15px;
    border-radius: 15px;
}

#lots-sales-user-detail .row .vl {
    margin: 10px -13px 30px;
    font-size: 40px;
}

#lots-sales-user-detail .row .vl b {
    font-size: 40px;
    color: #faa00a;
}


#lots-sales-user-detail .row .detail-buy .btn-cancel {
    margin: 10px 0 0 20px;
    width: auto;
}

#lots-sales-user-detail .row .detail-buy .col-4 {
    padding: 0;

}

@media (max-width: 1200px){

    #lots-sales-user-detail .row .detail-buy .btn-cancel, #lots-sales-user-detail .row .detail-buy .btn-create {
        margin: 10px 0;
        width: 100%;

    }
  
  }

#lots-sales-user-detail .row .detail-buy .detail-info .col-4 svg {
    color: #faa00a;

}

#lots-sales-user-detail .row .detail-buy .col-8 {
    padding: 0 0 0 4px;

}

#lots-sales-user-detail .row .detail-buy .detail-info b {
    font-size: 15px;
}

#lots-sales-user-detail .row .detail-buy .detail-info p {
    font-size: 14px;
}

#lots-sales-user-detail #nav-lots-sales-user-detail-social-custom {
    margin-left: -15px;
}

#lots-sales-user-detail #nav-lots-sales-user-detail-social-custom .icon-WhatsApp {
    color: #4caf50;
}

#lots-sales-user-detail #nav-lots-sales-user-detail-social-custom .icon-Facebook {
    color: #3578e5;
}

#lots-sales-user-detail #nav-lots-sales-user-detail-social-custom .icon-Instagram {
    color: #e775aa;
}


#lots-sales-user-detail .data-sheet{
    border-radius: 15px;
    padding: 20px;
}

#lots-sales-user-detail .data-sheet p, #lots-sales-user-detail .data-sheet b{
    margin-top: 10px;
}

#lots-sales-user-detail .data-sheet .colum-light-gray{
    margin-top: 20px;
    height: 50px;
    background-color: #f7f7f7;
}

#lots-sales-user-detail .data-sheet .colum-white{
    margin-top: 20px;
    height: 50px;
    background-color: #fff;
}

#annuonce .card-title b {
    color: #000;
}

#annuonce .title-custom-h2 {
    margin-top: 50px;
}

#annuonce .card-body{
    padding: 3rem 0px!important;
    border-radius: 15px;
}

#annuonce .card-title {
    margin-bottom: 25px;
}

#annuonce .card-body .btn-announce-default {
    margin: 10px 10px;
    width: 14rem;
    background: none;
    color: #676060;
    border: solid 1px #6760603b;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    padding: 5px 35px;
}

#annuonce .card-body .btn-announce-selected {
    margin: 10px 10px;
    background: #faa00a;
    color: #feedd1;
    border: solid 1px #faa00a;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 5px 35px;
    width: 14rem;

}

#annuonce .card-body #custom-races-form {
    width: 14rem;
    margin: 5px 10px;
}

#annuonce .card-body #custom-races-form input {
    margin: 5px 10px;
}

#annuonce .card-body #custom-races-form .form-group {
    margin: 0;
}

#annuonce .detail-lots .form-detail-lots {
    padding: 0px 15px 0px 15px;
}

#annuonce .address-lots .form-address-lots {
    padding: 0px 15px 0px 15px;
}

#annuonce  .detail-lots .form-group .label-vl-total {
    /* float: left; */
    font-size: 14px;
    margin: -18px 0px -16px 15px;
    color: #6c79a5;
}
#annuonce .detail-lots .form-group .labelControl {
    float: left;
    font-size: 14px;
    margin: -16px 5px 3px 20px;
    color: #6c79a5;
}

#annuonce .detail-lots .input-radio-pregnancy {
    display: flex;
}

#annuonce .detail-lots .label-nr-pregnancy {
    float: left;
    font-size: 14px;
    margin: 0px 15px 0px 15px;
    color: #6c79a5;
}

#annuonce .detail-lots .input-radio-pregnancy .form-check, #annuonce .detail-lots .input-radio-pregnancy .form-check {
    margin: 0px 15px 0px 15px;
}


#annuonce .address-lots .input-radio-shipper-access input, #annuonce .address-lots .input-radio-balance-access input, #annuonce .address-lots .input-radio-truck-access input {
    margin-left: 10px;
}

#annuonce .address-lots .input-radio-shipper-access label, #annuonce .address-lots .input-radio-balance-access label, #annuonce .address-lots .input-radio-truck-access label {
    margin-left: 27px;
}

#annuonce  .btn-create {
    width: 50%;
}

#annuonce .rowFinishDetail {
    display: block;
}
#annuonce .rowFinishDetailPriceTotal {
    padding-top: 5vh;
    justify-content: center;
}

#annuonce .vlPriceTotalInput {
    width: 50%;
}

#annuonce form .check-public label a {
    color: #faa00a;
    font-weight: 600;
}

.imgGuia {
    max-width: 880px;
    margin: 0 auto;
    padding-top: 50px;
}
#send-files-announce .card-body {
    border-radius: 15px;
}

#send-files-announce .card-body .btn-create {
    width: 50%;
}

#send-files-announce #photo-product .row, #send-files-announce #video-product .row, #send-files-announce #document-product .row {
    margin: 0 15px;
}

@media (max-width: 767px) {
    #send-files-announce .figure img {
        width: 50px!important;
        height: 50px!important;
    }
    
    #send-files-announce .figure video {
        width: 130px!important;
        height: 60px!important;
    }

    #send-files-announce .upload-foto-profile .upload-foto-profile-input {
        width: 50px!important;
        height: 50px!important;
    }
}

#send-files-announce .figure img {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
    object-fit: cover;
}

#send-files-announce .figure video {
    width: 230px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
}

#send-files-announce .upload-foto-profile input[type="file"] {
    display: none;
}

#send-files-announce .upload-foto-profile .upload-foto-profile-input {
    width: 100px;
    height: 100px;
    border: solid 1px #fddfae;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.btn-trash{
    position: absolute;
    margin-left: -50px;
    background: #f00!important;
    border-radius: 10px!important;
    border: none!important;
    color: #fff!important;
}

@media (max-width: 767px){
    .btn-trash{
        position: absolute;
        margin-left: -35px;
        width: 30px;
        height: 30px;
        padding: 0!important;
        background: #f00!important;
        border-radius: 10px!important;
        border: none!important;
        color: #fff!important;
    }
    .btn-trash svg{
        font-size: 13px;
    }
}
#product-buy .colunm {
    border-radius: 15px;
}

#product-buy .data-deposit .colunm h5 b {
    color: #000;
}

#product-buy .data-deposit .colunm, #product-buy .detail-product .colunm, #product-buy .finish-buy .colunm  {
    padding: 20px 0;
}

#product-buy .detail-product .colunm h5 b {
    color: #000;
}

#product-buy figure .figure-img {
    margin-bottom: 0;
    border-radius: 15px;
    width: 100%;
    height: 200px;
}

#product-buy .info-lots .titles h6{
    margin: 10px 10px;
    font-weight: 600;
}

#product-buy .info-lots .vl-show-details {
    width: 100%;
    margin-top: 50px;
}

#product-buy .info-lots .vl-show-details .type-coin {
    width: 100%;
    margin: 0px 0px -7px 30px;
    color: #faa00a;
}

@media (max-width: 768px) {
    #product-buy .info-lots .vl-show-details .type-coin {
        width: 100%;
        margin: 0px 70px -7px 0px;
        color: #faa00a;
    }
}

#product-buy .info-lots .vl-show-details .vl {
    margin-left: 15px;
    font-weight: 600;
    color: #faa00a;
}

#product-buy .finish-buy .colunm {
    margin: 0 -15px;
}


#product-buy .finish-buy .colunm .btn-create {
    width: auto;
    margin: 10px;
}

#product-buy .finish-buy .colunm .btn-cancel {
    margin: 10px;
}


#product-buy .btn-trans-option svg {
    margin-right: 15px;
    color: #929292;
}

#product-buy .btn-trans-option .fa-check-circle {
    color: #faa00a;
}

#product-buy .colunm {
    border-radius: 15px;
}

#product-buy .detail-product .colunm, #product-buy .finish-buy .colunm  {
    padding: 20px 0;
}

#product-buy .detail-product .colunm h5 b {
    color: #000;
}

#product-buy figure .figure-img {
    margin-bottom: 0;
    border-radius: 15px;
    width: 100%;
    height: 200px;
}

#product-buy .info-lots .vl-show-details {
    width: 100%;
    margin-top: 50px;
}

#product-buy .info-lots .vl-show-details .type-coin {
    width: 100%;
    margin: 0px 0px -7px 30px;
    color: #faa00a;
}

@media (max-width: 768px) {
    #product-buy .info-lots .vl-show-details .type-coin {
        width: 100%;
        margin: 0px 70px -7px 0px;
        color: #faa00a;
    }
}

#product-buy .info-lots .vl-show-details .vl {
    margin-left: 15px;
    font-weight: 600;
    color: #faa00a;
}

#product-buy .finish-buy .colunm {
    margin: 0 -15px;
}


#product-buy .finish-buy .colunm .btn-create {
    width: auto;
    margin: 10px;
}

#product-buy .finish-buy .colunm .btn-cancel {
    margin: 10px;
}

#register-action .row {
    margin-right: -5px;
    margin-left: -5px;
}

#register-action .title-custom-h2 {
    margin: 60px 0;
}

#register-action p, #register-action b {
    color: #686464;
}

#register-action .auction .btn-access, #register-action .company .btn-create  {
    width: 100%!important;
}

#register-action .auction .label-dt-time {
    left: 0;
    display: flex;
    margin-left: 15px;
    color: #757585;
}
#send-files-auction .card-body {
    border-radius: 15px;
}

#send-files-auction .card-body .btn-create {
    width: 50%;
}

#send-files-auction #photo-product .row, #send-files-auction #video-product .row, #send-files-auction #document-product .row {
    margin: 0 15px;
}

@media (max-width: 767px) {
    #send-files-auction .figure img {
        width: 50px!important;
        height: 50px!important;
    }
    
    #send-files-auction .figure video {
        width: 130px!important;
        height: 60px!important;
    }

    #send-files-auction .upload-foto-profile .upload-foto-profile-input {
        width: 50px!important;
        height: 50px!important;
    }
}

#send-files-auction .figure img {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
    object-fit: cover;
}

#send-files-auction .figure video {
    width: 230px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
}

#send-files-auction .upload-foto-profile input[type="file"] {
    display: none;
}

#send-files-auction .upload-foto-profile .upload-foto-profile-input {
    width: 100px;
    height: 100px;
    border: solid 1px #fddfae;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.btn-trash{
    position: absolute;
    margin-left: -50px;
    background: #f00!important;
    border-radius: 10px!important;
    border: none!important;
    color: #fff!important;
}

@media (max-width: 767px){
    .btn-trash{
        position: absolute;
        margin-left: -35px;
        width: 30px;
        height: 30px;
        padding: 0!important;
        background: #f00!important;
        border-radius: 10px!important;
        border: none!important;
        color: #fff!important;
    }
    .btn-trash svg{
        font-size: 13px;
    }
}
#auction-register-user .content{
    width: 100%;
    max-width: 500px;
}

#auction-register-user .form-group {
    display: flex;
}

#auction-register-user .form-group input{
    margin-right: 5px;
}

#auction-register-user form .btn-create{
    width: 100%;
}
.title-admin {
    margin-top: 25px;
    margin-bottom: 15px;
}

.title-admin h5{
    font-weight: 550;
}

#header-admin #basic-nav-dropdown {
    color: #000;
    margin-right: 10px;
    font-weight: 600;
}

.dropdown-menu {
    min-width: 18rem!important;
    left: 40px!important;
}

@media (max-width: 992px){
    #header-admin #nav-dropdown-custom {
      right: 50px;
      position: absolute;
    }
  
    #header-admin #nav-custom-top .dropdown-menu {
      width: 100%;
      border-radius: .65rem;
      left: 80px;
      position: absolute;
    }
  
    #header-admin #basic-nav-dropdown {
      color: rgb(0, 0, 0);
      margin-left: 280px;
    }
  
    #header-admin #nav-dropdown-custom #image-profile-icon-dropdown img{
      float: left;
      margin-left: 10px;
    }

    #header-admin #nav-dropdown-custom img {
      float: left;
      margin-left: 241px;
    }
  
  }
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

.sidenav---sidenav---_2tBP {
    background: #000000!important;
}

.sidenav---sidenav---_2tBP .logo {
    display: flex;
    width: 100%;
    justify-content: center!important
}

.sidenav---sidenav-nav---3tvij {
    padding: 50px 30px!important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px!important;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    width: 5px!important;
}


#menu-side #basic-nav-dropdown {
    color: #000;
    margin-right: 10px;
    font-weight: 600;
}

.dropdown-menu {
    min-width: 18rem!important;
    left: 40px!important;
}

@media (max-width: 992px){
    #menu-side #nav-dropdown-custom {
      right: 0px;
      position: relative;
    }
  
    #menu-side #nav-custom-top .dropdown-menu {
      width: 100%;
      border-radius: .65rem;
      left: 80px;
      position: absolute;
    }
  
    #menu-side #basic-nav-dropdown {
      color: #fff;
      margin-left: 0px;
    }

    #menu-side #nav-dropdown-custom img{
        float: left;
        margin-left: 0px;
      }
  
    #menu-side #nav-dropdown-custom #image-profile-icon-dropdown img{
      float: left;
      margin-left: 10px;
    }
  
  }
#home-admin .resume {
    margin-left: 260px;
    width: 100%;
}

#home-admin .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #home-admin .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#home-admin .row-resume {
    margin-left: 0;
    margin-right: 0;
}

#home-admin .row-resume .lots-graphic {
    border-radius: 15px;
}

#home-admin .row-resume .new-user {
    border-radius: 15px;
    display: flex;
}

#home-admin .row-resume .new-user .icon-user {
    padding: 20px;
}

#home-admin .row-resume .new-user .description {
    margin-left: 20px;
    margin-top: 10px;
}

#home-admin .row-resume .new-user .description h1 {
    font-weight: 600;
}

#home-admin .row-resume .new-user .description p {
    font-weight: 600;
}

#home-admin .row-resume .new-lots {
    border-radius: 15px;
    display: flex;
    
}

#home-admin .row-resume .new-lots .figure {
    padding: 20px;
}

#home-admin .row-resume .new-lots .description {
    margin-left: 20px;
    margin-top: 10px;
}

#home-admin .row-resume .new-lots .description h1 {
    font-weight: 600;
}

#home-admin .row-resume .new-lots .description p {
    font-weight: 600;
}

#home-admin .new-register-lots {
    border-radius: 15px;
}

#home-admin .new-register-lots #lots {
    display: flex;
}

#home-admin .new-register-lots #lots .figure {
    padding: 10px;
}

#home-admin .new-register-lots #lots .figure img {
    width: 120px;
    height: 100px;
    object-fit: cover;
}

#home-admin .new-register-lots #lots .description {
    padding: 10px;

}

#home-admin .new-register-lots #lots .description h5 {
    font-weight: 600;
}

#home-admin .new-register-lots #lots .description h6, #home-admin .new-register-lots #lots .description svg {
    margin-top: 40px;
    color: #686464;
    font-weight: 550;
}

#home-admin .new-register-lots #lots .description svg {
    margin-top: 40px;
    color: #686464;
    font-weight: 550;
}
#requests .resume {
    margin-left: 260px;
    width: 100%;
}

#requests .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #requests .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#requests .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}


#requests #navbar-custom .icon-search {
    right: 31px;
    height: 28px;
    margin: 10px 0;
}

#requests #navbar-custom .form-control:focus {
    background-color: #0000!important;
}

#requests #navbar-custom .input-group>.form-control {
    height: calc(1.1em + .80rem + 5px);
    margin: 10px 0;
}


#requests #navbar-custom .text-filter{
    padding: 10px 10px;
}


#requests .row-requests {
    margin-left: 0;
    margin-right: 0;
}

#requests .row-requests .done-deal {
    border-radius: 15px;
    display: flex;
}

#requests .row-requests .done-deal .icon-user {
    padding: 20px;
}

#requests .row-requests .done-deal .description {
    margin-left: 20px;
    margin-top: 10px;
}

#requests .row-requests .done-deal .description h1 {
    font-weight: 600;
}

#requests .row-requests .done-deal .description p {
    font-weight: 600;
}

#requests .row-requests .sales-category {
    height: 100%;
    border-radius: 15px;
    padding: 20px 0;
}

#requests .row-requests .sales-months {
    height: 100%;
    border-radius: 15px;
    padding: 20px 0;
}

#requests .row-requests .list-register-request-lots {
    padding: 15px 0;
    border-radius: 15px;
}

#requests .row-requests .navbar-light .form-check {
    position: relative;
    display: block;
    padding-left: 3.25rem;
}

#requests .row-requests .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    background-color: rgb(243, 232, 232);
    border-color: rgba(20, 20, 20, 0.575);
}

#requests .row-requests .navbar-light .navbar-toggler-icon {
    width: 100%;
    padding: 5px 0;
    color: #000;
    background-image: none;
}

#requests .row-requests .navbar-light .navbar-toggler-icon:before {
    content: 'Filtro';
    width: 100%;
    padding: 5px 0;
    color: #000;
}
#request-detail .resume {
    margin-left: 260px;
    width: 100%;
}

#request-detail .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #request-detail .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#request-detail .data-lot-request {
    border-radius: 15px;
    padding: 25px;
    margin-left: -15px;
}

#request-detail .data-lot-request .info-lote {
    margin-top: 50px;
}

#request-detail .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}

#request-detail .nav .btn-link{
    width: auto;
}

#requests-detail .resume {
    margin-left: 260px;
    width: 100%;
}

#requests-detail .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #requests-detail .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#requests-detail .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}

#requests-detail .btn-link {
    width: auto;
}


#requests-detail .row-requests-detail {
    margin-left: 0;
    margin-right: 0;
}


p, b {
    color: #686464;
}

#requests-detail .resume-request {
    border-radius: 15px;
    margin-left: -15px;
}

#requests-detail #detail_status {
    border-radius: 15px;
    padding: 20px 0;
}

#requests-detail #detail_status .progress {
    margin-top: -25px;
    height: 4px;
}

#requests-detail .text-progress {
    display: flex;
}

#requests-detail .text-progress, #requests-detail .text-progress-status {
    padding-top: 20px;
    display: flex;
}


#requests-detail #detail_status .icon-progress {
    z-index: 10000;
    position: relative;
}

#requests-detail #detail_status .icon-progress .icon-1, #requests-detail #detail_status .icon-progress .icon-2, #requests-detail #detail_status .icon-progress .icon-3{
    margin-right: 24%;
}

#requests-detail #detail_status .icon-progress .icon-4{
    margin-left: 10px;
}

#requests-detail #detail_status .icon-progress .icon-1{
    margin-left: -6px;
}

#requests-detail #detail_status .icon-progress .cicle-icon-1{
    background: #c7c7c7;
    width: 40px;
    height: 40px;
    padding-top: 9px;
    color: #fff;
    border-radius: 40px;
    text-align: center;
    margin-top: 5px;
    margin-left: 13px;
}

#requests-detail #detail_status .icon-progress .cicle-icon-extra-color{
    background: #faa00a;

}

#requests-detail #detail_status .text-progress #text-1 {
    margin-left: 5%;
}

#requests-detail #detail_status .text-progress #text-2{
    margin-left: 9%;
}

#requests-detail #detail_status .text-progress #text-3{
    margin-left: 10%;
}

#requests-detail #detail_status .text-progress #text-4{
    margin-left: 10%;
}

#requests-detail #detail_status .text-progress-status #text-status-1{
    margin-left: 13%;
}
#requests-detail #detail_status .text-progress-status #text-status-2{
    margin-left: 8%;
}
#requests-detail #detail_status .text-progress-status #text-status-3{
    margin-left: 5%;
}
#requests-detail #detail_status .text-progress-status #text-status-4{
    margin-left: 4%;
}

@media (max-width: 1200px) {
    #requests-detail #detail_status .icon-progress .icon-1, #requests-detail #detail_status .icon-progress .icon-2, #requests-detail #detail_status .icon-progress .icon-3 {
        margin-right: 22%!important;
    }

    #requests-detail .text-progress, #requests-detail .text-progress-status {
        font-size: 14px;
    }

    #requests-detail #detail_status .text-progress-status #text-status-1 {
        margin-left: 11%;
    }

    #requests-detail #detail_status .text-progress-status #text-status-2 {
        margin-left: 8%;
    }

    #requests-detail #detail_status .text-progress-status #text-status-3 {
        margin-left: 4%;
    }

    #requests-detail #detail_status .text-progress-status #text-status-4 {
        margin-left: 2%;
    }
}


@media (max-width: 991px) {
    #requests-detail #detail_status .progress-bar-vertical {
        width: 5px;
        min-height: 300px;
        display: flex;
        align-items: flex-start;
        margin-right: 20px;
        float: left;
      }
    
    #requests-detail #detail_status .progress-bar-vertical .progress-bar {
        width: 100%;
        height: 0;
        transition: height 0.6s ease;
    }

    #requests-detail #detail_status  .bar-progress-vertical .progress {
        margin-top: 0;
    }

    #requests-detail #detail_status .bar-progress-vertical {
        margin-top: 30px;
    }

    #requests-detail #detail_status .bar-progress-vertical .icon-progress {
        margin-right: -26px;
        margin-top: 35px;
        display: grid;
    }

    #requests-detail #detail_status .bar-progress-vertical .icon-progress .icon-vertical-1 {
        margin-top: -50px;
    }

    #requests-detail #detail_status .bar-progress-vertical .icon-progress .icon-vertical-2 {
        margin-top: 40px;
    }

    #requests-detail #detail_status .bar-progress-vertical .icon-progress .icon-vertical-3 {
        margin-top: 50px;
    }

    #requests-detail #detail_status .bar-progress-vertical .icon-progress .icon-vertical-4 {
        margin-top: 50px;
    }

    #requests-detail #detail_status .bar-progress-vertical .icon-progress .cicle-icon-1 {
        margin-left: 6px;
    }

    #requests-detail #detail_status .bar-progress-vertical #text-vertical-status-1 {
        margin-top: 0px;
    }

    #requests-detail #detail_status .bar-progress-vertical #text-vertical-status-2, #requests-detail #detail_status .bar-progress-vertical #text-vertical-status-3, #requests-detail #detail_status .bar-progress-vertical #text-vertical-status-4 {
        margin-top: 78px;
    }
}

#requests-detail #detail_status .product-info {
    margin: 50px 0 0 10px;
}

#requests-detail #detail_status .btn-create {
    margin: 0px 0 0 10px;
    width: auto;
    height: auto;
}

#requests-detail #detail_status svg, #requests-detail #data-buyer svg, #requests-detail #data-salesman svg {
    color: #7f7f7f;
    margin-top: 10px;
}

#requests-detail #data-buyer h4, #requests-detail #data-salesman h4 {
    font-weight: 600;
}

#requests-detail .data-lot-request {
    border-radius: 15px;
    padding: 25px;
    margin-left: -15px;
}

#requests-detail .data-lot-request .info-lote {
    margin-top: 50px;
}

#requests-detail .progress_status form,  #requests-detail .progress_status form .row{
    align-items: center;
    justify-content: center;
    width: 100%;
}

#requests-detail .progress_status {
    padding: 20px 0 100px 0;
}

#requests-detail .progress_status form .btn-create{
    width: 100%;
}
#requests .resume {
    margin-left: 260px;
    width: 100%;
}

#requests .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #requests .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#requests .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}


#requests #navbar-custom .icon-search {
    right: 31px;
    height: 28px;
    margin: 10px 0;
}

#requests #navbar-custom .form-control:focus {
    background-color: #0000!important;
}

#requests #navbar-custom .input-group>.form-control {
    height: calc(1.1em + .80rem + 5px);
    margin: 10px 0;
}


#requests #navbar-custom .text-filter{
    padding: 10px 10px;
}


#requests .row-requests {
    margin-left: 0;
    margin-right: 0;
}

#requests .row-requests .done-deal {
    border-radius: 15px;
    display: flex;
}

#requests .row-requests .done-deal .icon-user {
    padding: 20px;
}

#requests .row-requests .done-deal .description {
    margin-left: 20px;
    margin-top: 10px;
}

#requests .row-requests .done-deal .description h1 {
    font-weight: 600;
}

#requests .row-requests .done-deal .description p {
    font-weight: 600;
}

#requests .row-requests .sales-category {
    height: 100%;
    border-radius: 15px;
    padding: 20px 0;
}

#requests .row-requests .sales-months {
    height: 100%;
    border-radius: 15px;
    padding: 20px 0;
}

#requests .row-requests .list-register-request-lots {
    padding: 15px 0;
    border-radius: 15px;
}

#requests .row-requests .navbar-light .form-check {
    position: relative;
    display: block;
    padding-left: 3.25rem;
}

#requests .row-requests .navbar-light .navbar-toggler-icon {
    width: 100%;
    padding: 5px 0;
    color: #000;
    background-image: none;
}

#requests .row-requests .navbar-light .navbar-toggler-icon:before {
    content: 'Filtro';
    width: 100%;
    padding: 5px 0;
    color: #000;
}
#requests-negotiation-detail .resume {
    margin-left: 260px;
    width: 100%;
}

#requests-negotiation-detail .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #requests-negotiation-detail .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#requests-negotiation-detail .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}

#requests-negotiation-detail .btn-link {
    width: auto;
}


#requests-negotiation-detail .row-requests-negotiation-detail {
    margin-left: 0;
    margin-right: 0;
}


p, b {
    color: #686464;
}

#requests-negotiation-detail .resume-request {
    border-radius: 15px;
    margin-left: -15px;
}

#requests-negotiation-detail #detail_status {
    border-radius: 15px;
    padding: 20px 0;
}

#requests-negotiation-detail #detail_status .product-info {
    margin: 50px 0 0 10px;
}

#requests-negotiation-detail #detail_status svg, #requests-negotiation-detail #data-buyer svg, #requests-negotiation-detail #data-salesman svg {
    color: #7f7f7f;
    margin-top: 10px;
}

#requests-negotiation-detail #detail_status .red {
    background: #d33d3dd2!important;
}

#requests-negotiation-detail #detail_status .btn-create, #requests-negotiation-detail #detail_status .btn-cancel {
    margin: 10px!important;
}

#requests-negotiation-detail #data-buyer h4, #requests-negotiation-detail #data-salesman h4 {
    font-weight: 600;
}

#requests-negotiation-detail .data-lot-request {
    border-radius: 15px;
    padding: 25px;
    margin-left: -15px;
}

#requests-negotiation-detail .data-lot-request .info-lote {
    margin-top: 50px;
}


#auction .resume {
    margin-left: 260px;
    width: 100%;
}

#auction .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #auction .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#auction .row-auction {
    margin-left: 0;
    margin-right: 0;
}

#auction .row-auction .list-register-auction-lots {
    padding: 15px 0;
    border-radius: 15px;
}

#auction .create-auction {
    margin-left: 0%;
    margin-right: 15px;
}

#auction .create-auction .btn-create {
    width: auto;
}

#admin-auction-detail .resume {
    margin-left: 260px;
    width: 100%;
}

#admin-auction-detail .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #admin-auction-detail .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#admin-auction-detail .col-12 {
    padding-right: 0px;
}

#admin-auction-detail .menu-left {
    padding: 0px 20px 0px 0px;
}

#admin-auction-detail #side-info-auction {
    padding-left: 0px;
}

#admin-auction-detail #side-info-auction h3 {
    padding-bottom: 25px;
}

#admin-auction-detail .menu-left .title-custom-h2{
    margin-left: -15px;
}

#admin-auction-detail .menu-left p{
    margin-top: -22px;
    font-size: 13px;
    color: #676060;
}

#admin-auction-detail .menu-left h5 {
    margin: 13px 0;
}

#admin-auction-detail .row {
    margin-right: 0;
    margin-left: 0;
}

#admin-auction-detail .colunm {
    padding: 10px 10px;
    border-radius: 15px;
}

#admin-auction-detail .item-product {
    margin-right: 0px;
    margin-left: 0px;
    margin: 10px 0;

}

#admin-auction-detail .colunm .col-12, #admin-auction-detail .colunm .col-sm-3, #admin-auction-detail .colunm .col-sm-9 {
    padding-right: 0px;
    padding-left: 0px;
}

#admin-auction-detail .list-right h3 {
    margin: 25px 10px;
}

#admin-auction-detail .list-right figure {
    margin: 0;
    min-width: 100%;
}

#admin-auction-detail .list-right figure .figure-img {
    margin-bottom: 0;
    border-radius: 15px;
    width: 100%;
    height: 26vh;
    max-height: 32vh;
    object-fit: cover;
}

@media (max-width: 991px) {
    #admin-auction-detail .list-right figure .figure-img {
        height: auto;
    }
}


#admin-auction-detail .list-right .info-lots{
    padding-right: 15px;
    padding-left: 15px;
}

#admin-auction-detail .list-right .info-lots .titles h2{
    margin: 10px 10px;
    font-weight: 600;
    font-size: 1.5rem;
}

@media (max-width: 991px) {
    #admin-auction-detail .list-right .info-lots .titles h2{
        font-size: 1.5rem;
    }
}

#admin-auction-detail .list-right .info-lots .titles {
    justify-content: space-between;
}

#admin-auction-detail .list-right .info-lots .titles p{
    margin: 10px 3px;
    background-color:#faa00a;
    float: right;
    padding: 5px 7px;
    font-size: 13px;
    border-radius: 25px;
    color: #fff;
    display:flex;
    justify-content:right
}

@media (max-width: 991px) {
    #admin-auction-detail .list-right .info-lots .titles p{
        padding: 7px 15px;
        margin: 0px 10px 10px 10px;
        font-size: 10px;
    }
}

#admin-auction-detail .list-right .info-lots .description p{
    color: #676060;
    text-align: left;
}

#admin-auction-detail .list-right .info-lots .vl-show-details {
    width: 100%;
    margin-top: 50px;
}
@media (max-width: 991px) {
    #admin-auction-detail .list-right .info-lots .vl-show-details {
        width: 100%;
        margin-top: 10px;
    }
}

#admin-auction-detail .list-right .info-lots .vl-show-details .type-coin {
    width: 100%;
    margin: 0px 0px -7px 30px;
    color: #faa00a;
}

@media (max-width: 768px) {
    #admin-auction-detail .list-right .info-lots .vl-show-details .type-coin {
        width: 100%;
        margin: 0px 70px -7px 0px;
        color: #faa00a;
    }
}

#admin-auction-detail .list-right .info-lots .vl-show-details .vl {
    margin-left: 15px;
    font-weight: 600;
    color: #faa00a;
}

@media (max-width: 991px) {
    #admin-auction-detail .list-right .info-lots .vl-show-details .vl {
        font-size: 1.3rem;
    }
}

#admin-auction-detail .list-right .info-lots .vl-show-details a {
    margin: 0px;
    padding: 0.55rem .75rem;
    width: 150px;
}

@media (max-width: 991px) {
    #admin-auction-detail .list-right a {
        margin: 0px;
        width: 100%;
    }
}

#admin-auction-detail .card .card-img-top {
    height: 200px;
    object-fit: cover;
}

#admin-auction-detail .register-lote {
    padding: 15px 0;
}


#admin-auction-detail .register-lote .btn-create{
    width: auto;
    margin: 10px;
}

#admin-auction-detail .text-finish-auction {
    background: #ececec;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    padding: 20px;
    color:rgb(0, 0, 0);
    border-radius: 15px;
}


#admin-auction-detail .menu-left .btn {
    width: 200px;
    margin: 10px 0;
}

#lots-detail .resume {
    margin-left: 260px;
    width: 100%;
}

#lots-detail .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #lots-detail .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#lots-detail .data-lot-request {
    border-radius: 15px;
    padding: 25px;
    margin-left: -15px;
}

#lots-detail .data-lot-request .info-lote {
    margin-top: 50px;
}

#lots-detail .progress_status {
    padding: 20px 0 20px 0;
}
#lots-detail .progress_status .row{
    padding: 10px 0 00px 0;
}

#lots-detail .progress_status .btn-create{
    width: 100%;
}

#lots-detail .tag-auction {
    background: rgb(167, 164, 164);
    color: rgb(0, 0, 0);
    width: 150px;
    height: 38px;
    font-size: 18px;
    border-radius: 5px;
    padding-top: 5px;
    margin: 0 10px;
    text-align: center;
}

#lots-detail .tag-request {
    background: rgb(140, 163, 7);
    color: rgb(255, 255, 255);
    width: 150px;
    height: 38px;
    font-size: 18px;
    border-radius: 5px;
    padding-top: 5px;
    margin: 0 10px;
    text-align: center;
}
#bid .resume {
    margin-left: 260px;
    width: 100%;
}

#bid .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #bid .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#bid {
    margin-left: 0;
    margin-right: 0;
}

#bid .list-register-bid {
    padding: 10px;
}

#bid .btn-create {
    width: auto!important;
}
#bid-request  .resume {
    margin-left: 260px;
    width: 100%;
}

#bid-request  .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #bid-request  .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#bid-request {
    margin-left: 0;
    margin-right: 0;
}

#bid-request .request {
    padding: 20px;
}

#bid-request .btn-create {
    width: auto;
}

#bid-request .figure img {
    margin-top: 15px;
    object-fit: cover;
    width: 150px;
    height: 150px;
}
#auction-create-search-user .resume {
    margin-left: 260px;
    width: 100%;
}

#auction-create-search-user .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #auction-create-search-user .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#auction-create-search-user .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}


#auction-create-search-user .icon-search {
    right: 31px;
    height: 28px;
    margin: 10px 0;
}

#auction-create-search-user .form-control:focus {
    background-color: #0000!important;
}

#auction-create-search-user .input-group>.form-control {
    height: calc(1.1em + .99rem + 5px);
    margin: 10px 0;
}
#register-action .resume {
    margin-left: 260px;
    width: 100%;
}

#register-action .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #register-action .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#register-action .row {
    margin-right: -5px;
    margin-left: -5px;
}

#register-action .title-custom-h2 {
    margin: 60px 0;
}

#register-action p, #register-action b {
    color: #686464;
}

#register-action .auction .btn-access, #register-action .company .btn-create  {
    width: 100%!important;
}

#register-action .auction .label-dt-time {
    left: 0;
    display: flex;
    margin-left: 15px;
    color: #757585;
}
#create-files-auction .resume {
    margin-left: 260px;
    width: 100%;
}

#create-files-auction .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #create-files-auction .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#create-files-auction .card-body {
    border-radius: 15px;
}

#create-files-auction .card-body .btn-create {
    width: 50%;
}

#create-files-auction #photo-product .row, #create-files-auction #video-product .row, #create-files-auction #document-product .row {
    margin: 0 15px;
}

@media (max-width: 767px) {
    #create-files-auction .figure img {
        width: 50px!important;
        height: 50px!important;
    }
    
    #create-files-auction .figure video {
        width: 130px!important;
        height: 60px!important;
    }

    #create-files-auction .upload-foto-profile .upload-foto-profile-input {
        width: 50px!important;
        height: 50px!important;
    }
}

#create-files-auction .figure img {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
    object-fit: cover;
}

#create-files-auction .figure video {
    width: 230px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
}

#create-files-auction .upload-foto-profile input[type="file"] {
    display: none;
}

#create-files-auction .upload-foto-profile .upload-foto-profile-input {
    width: 100px;
    height: 100px;
    border: solid 1px #fddfae;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.btn-trash{
    position: absolute;
    margin-left: -50px;
    background: #f00!important;
    border-radius: 10px!important;
    border: none!important;
    color: #fff!important;
}

@media (max-width: 767px){
    .btn-trash{
        position: absolute;
        margin-left: -35px;
        width: 30px;
        height: 30px;
        padding: 0!important;
        background: #f00!important;
        border-radius: 10px!important;
        border: none!important;
        color: #fff!important;
    }
    .btn-trash svg{
        font-size: 13px;
    }
}
#admin-auction-register-user .resume {
    margin-left: 260px;
    width: 100%;
}

#admin-auction-register-user .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #admin-auction-register-user .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#admin-auction-register-user .content{
    width: 100%;
}

#admin-auction-register-user .form-group {
    display: flex;
}

#admin-auction-register-user .form-group input{
    margin-right: 5px;
}

#admin-auction-register-user form .btn-create{
    width: 100%;
}
#lots .resume {
    margin-left: 260px;
    width: 100%;
}

#lots .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #lots .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#lots .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}


#lots #navbar-custom .icon-search {
    right: 31px;
    height: 28px;
    margin: 10px 0;
}

#lots #navbar-custom .form-control:focus {
    background-color: #0000!important;
}

#lots #navbar-custom .input-group>.form-control {
    height: calc(1.1em + .80rem + 5px);
    margin: 10px 0;
}


#lots #navbar-custom .text-filter{
    padding: 10px 10px;
}


#lots {
    margin-left: 0;
    margin-right: 0;
}

.icon-edit {
    cursor: pointer;
    color: #686464;
}

#lots .list-register-lots {
    padding: 15px 0;
    border-radius: 15px;
}

#lots .list-register-lots figure {
    margin: 0px 5px 0 40px;
}

#lots .list-register-lots p, #lots .list-register-lots svg {
    margin: 20px 0px 0px 0px;
}

#lots .navbar-light .form-check {
    position: relative;
    display: block;
    padding-left: 3.25rem;
}

#lots .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    background-color: rgb(243, 232, 232);
    border-color: rgba(20, 20, 20, 0.575);
}

#lots .navbar-light .navbar-toggler-icon {
    width: 100%;
    padding: 5px 0;
    color: #000;
    background-image: none;
}

#lots .navbar-light .navbar-toggler-icon:before {
    content: 'Filtro';
    width: 100%;
    padding: 5px 0;
    color: #000;
}

#lots .btn-create {
    width: 180px;
    margin-top: 15px;
}

.modal-backdrop {
    background-color: #0000003b!important;
}
#lots-create-search-user .resume {
    margin-left: 260px;
    width: 100%;
}

#lots-create-search-user .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #lots-create-search-user .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#lots-create-search-user .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}


#lots-create-search-user .icon-search {
    right: 31px;
    height: 28px;
    margin: 10px 0;
}

#lots-create-search-user .form-control:focus {
    background-color: #0000!important;
}

#lots-create-search-user .input-group>.form-control {
    height: calc(1.1em + .99rem + 5px);
    margin: 10px 0;
}
#admin-annuonce .resume {
    margin-left: 260px;
    width: 100%;
}

#admin-annuonce .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #admin-annuonce .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#admin-annuonce .card-title b {
    color: #000;
}

#admin-annuonce .title-custom-h2 {
    margin-top: 50px;
}

#admin-annuonce .card-body{
    padding: 3rem 0px!important;
    border-radius: 15px;
}

#admin-annuonce .card-title {
    margin-bottom: 25px;
}

#admin-annuonce .card-body .btn-announce-default {
    margin: 10px 10px;
    width: 14rem;
    background: none;
    color: #676060;
    border: solid 1px #6760603b;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    padding: 5px 35px;
}

#admin-annuonce .card-body .btn-announce-selected {
    margin: 10px 10px;
    background: #faa00a;
    color: #feedd1;
    border: solid 1px #faa00a;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 5px 35px;
    width: 14rem;

}

#admin-annuonce .card-body #custom-races-form {
    width: 14rem;
    margin: 5px 10px;
}

#admin-annuonce .card-body #custom-races-form input {
    margin: 5px 10px;
}

#admin-annuonce .card-body #custom-races-form .form-group {
    margin: 0;
}

#admin-annuonce .detail-lots .form-detail-lots {
    padding: 0px 15px 0px 15px;
}

#admin-annuonce .address-lots .form-address-lots {
    padding: 0px 15px 0px 15px;
}

#admin-annuonce  .detail-lots .form-group .label-vl-total {
    /* float: left; */
    font-size: 14px;
    margin: -18px 0px -16px 15px;
    color: #6c79a5;
}
#admin-annuonce .detail-lots .form-group .labelControl {
    float: left;
    font-size: 14px;
    margin: -16px 5px 3px 20px;
    color: #6c79a5;
}

#admin-annuonce .detail-lots .input-radio-pregnancy {
    display: flex;
}

#admin-annuonce .detail-lots .label-nr-pregnancy {
    float: left;
    font-size: 14px;
    margin: 0px 15px 0px 15px;
    color: #6c79a5;
}

#admin-annuonce .detail-lots .input-radio-pregnancy .form-check, #admin-annuonce .detail-lots .input-radio-pregnancy .form-check {
    margin: 0px 15px 0px 15px;
}


#admin-annuonce .address-lots .input-radio-shipper-access input, #admin-annuonce .address-lots .input-radio-balance-access input, #admin-annuonce .address-lots .input-radio-truck-access input {
    margin-left: 10px;
}

#admin-annuonce .address-lots .input-radio-shipper-access label, #admin-annuonce .address-lots .input-radio-balance-access label, #admin-annuonce .address-lots .input-radio-truck-access label {
    margin-left: 27px;
}

#admin-annuonce  .btn-create {
    width: 50%;
}

#admin-annuonce .rowFinishDetail {
    display: block;
}
#admin-annuonce .rowFinishDetailPriceTotal {
    padding-top: 5vh;
    justify-content: center;
}

#admin-annuonce .vlPriceTotalInput {
    width: 50%;
}

#admin-annuonce form .check-public label a {
    color: #faa00a;
    font-weight: 600;
}
#send-files-announce-admin .resume {
    margin-left: 260px;
    width: 100%;
}

#send-files-announce-admin .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #send-files-announce-admin .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}
#send-files-announce-admin .row {
    margin-right: 0px;
    margin-left: 0px;
}

#send-files-announce-admin .card-body {
    border-radius: 15px;
}

#send-files-announce-admin .card-body .btn-create {
    width: 50%;
}

#send-files-announce-admin #photo-product .row, #send-files-announce-admin #video-product .row, #send-files-announce-admin #document-product .row {
    margin: 0 15px;
}

@media (max-width: 767px) {
    #send-files-announce-admin .figure img {
        width: 50px!important;
        height: 50px!important;
    }
    
    #send-files-announce-admin .figure video {
        width: 130px!important;
        height: 60px!important;
    }

    #send-files-announce-admin .upload-foto-profile .upload-foto-profile-input {
        width: 50px!important;
        height: 50px!important;
    }
}

#send-files-announce-admin .figure img {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
    object-fit: cover;
}

#send-files-announce-admin .figure video {
    width: 230px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
}

#send-files-announce-admin .upload-foto-profile input[type="file"] {
    display: none;
}

#send-files-announce-admin .upload-foto-profile .upload-foto-profile-input {
    width: 100px;
    height: 100px;
    border: solid 1px #fddfae;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.btn-trash{
    position: absolute;
    margin-left: -50px;
    background: #f00!important;
    border-radius: 10px!important;
    border: none!important;
    color: #fff!important;
}

@media (max-width: 767px){
    .btn-trash{
        position: absolute;
        margin-left: -35px;
        width: 30px;
        height: 30px;
        padding: 0!important;
        background: #f00!important;
        border-radius: 10px!important;
        border: none!important;
        color: #fff!important;
    }
    .btn-trash svg{
        font-size: 13px;
    }
}
#lots-detail .resume {
    margin-left: 260px;
    width: 100%;
}

#lots-detail .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #lots-detail .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#lots-detail .data-lot-request {
    border-radius: 15px;
    padding: 25px;
    margin-left: -15px;
}

#lots-detail .data-lot-request .info-lote {
    margin-top: 50px;
}

#lots-detail .progress_status {
    padding: 20px 0 20px 0;
}
#lots-detail .progress_status .row{
    padding: 10px 0 00px 0;
}

#lots-detail .progress_status .btn-create{
    width: 100%;
}
#admin-lots-update .resume {
    margin-left: 260px;
    width: 100%;
}

#admin-lots-update .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #admin-lots-update .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#admin-lots-update .row {
    margin-left: 0;
    margin-right: 0;
}

#admin-lots-update .card-title b {
    color: #000;
}

#admin-lots-update .title-custom-h2 {
    margin-top: 50px;
}

#admin-lots-update .card-body{
    padding: 3rem 0px!important;
    border-radius: 15px;
}

#admin-lots-update .card-title {
    margin-bottom: 25px;
}

#admin-lots-update .card-body .btn-announce-default {
    margin: 10px 10px;
    width: 14rem;
    background: none;
    color: #676060;
    border: solid 1px #6760603b;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    padding: 5px 35px;
}

#admin-lots-update .card-body .btn-announce-selected {
    margin: 10px 10px;
    background: #faa00a;
    color: #feedd1;
    border: solid 1px #faa00a;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 5px 35px;
    width: 14rem;

}

#admin-lots-update .card-body #custom-races-form {
    width: 14rem;
    margin: 5px 10px;
}

#admin-lots-update .card-body #custom-races-form input {
    margin: 5px 10px;
}

#admin-lots-update .card-body #custom-races-form .form-group {
    margin: 0;
}

#admin-lots-update .detail-lots .form-detail-lots {
    padding: 0px 15px 0px 15px;
}

#admin-lots-update .address-lots .form-address-lots {
    padding: 0px 15px 0px 15px;
}

#admin-lots-update  .detail-lots .form-group .label-vl-total {
    /* float: left; */
    font-size: 14px;
    margin: -18px 0px -16px 15px;
    color: #6c79a5;
}
#admin-lots-update .detail-lots .form-group .labelControl {
    float: left;
    font-size: 14px;
    margin: -16px 5px 3px 20px;
    color: #6c79a5;
}

#admin-lots-update .detail-lots .input-radio-pregnancy {
    display: flex;
}

#admin-lots-update .detail-lots .label-nr-pregnancy {
    float: left;
    font-size: 14px;
    margin: 0px 15px 0px 15px;
    color: #6c79a5;
}

#admin-lots-update .detail-lots .input-radio-pregnancy .form-check, #admin-lots-update .detail-lots .input-radio-pregnancy .form-check {
    margin: 0px 15px 0px 15px;
}


#admin-lots-update .address-lots .input-radio-shipper-access input, #admin-lots-update .address-lots .input-radio-balance-access input, #admin-lots-update .address-lots .input-radio-truck-access input {
    margin-left: 10px;
}

#admin-lots-update .address-lots .input-radio-shipper-access label, #admin-lots-update .address-lots .input-radio-balance-access label, #admin-lots-update .address-lots .input-radio-truck-access label {
    margin-left: 27px;
}

#admin-lots-update  .btn-create {
    width: 50%;
}

#admin-lots-update .rowFinishDetail {
    display: block;
}
#admin-lots-update .rowFinishDetailPriceTotal {
    padding-top: 5vh;
    justify-content: center;
}

#admin-lots-update .vlPriceTotalInput {
    width: 50%;
}

#admin-lots-update form .check-public label a {
    color: #faa00a;
    font-weight: 600;
}
#files-update-admin .resume {
    margin-left: 260px;
    width: 100%;
}

#files-update-admin .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #files-update-admin .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}
#files-update-admin .row {
    margin-right: 0px;
    margin-left: 0px;
}

#files-update-admin .card-body {
    border-radius: 15px;
}

#files-update-admin .card-body .btn-create {
    width: 50%;
}

#files-update-admin #photo-product .row, #files-update-admin #video-product .row, #files-update-admin #document-product .row {
    margin: 0 15px;
}

@media (max-width: 767px) {
    #files-update-admin .figure img {
        width: 50px!important;
        height: 50px!important;
    }
    
    #files-update-admin .figure video {
        width: 130px!important;
        height: 60px!important;
    }

    #files-update-admin .upload-foto-profile .upload-foto-profile-input {
        width: 50px!important;
        height: 50px!important;
    }
}

#files-update-admin .figure img {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin: 0px 5px;
    padding: 10px;
    object-fit: cover;
}

#files-update-admin .figure video {
    width: 230px;
    height: 100px;
    border-radius: 15px;
    margin: 0 10px;
}

#files-update-admin .upload-foto-profile input[type="file"] {
    display: none;
}

#files-update-admin .upload-foto-profile .upload-foto-profile-input {
    width: 100px;
    height: 100px;
    border: solid 1px #fddfae;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.btn-trash{
    position: absolute;
    margin-left: -50px;
    background: #f00!important;
    border-radius: 10px!important;
    border: none!important;
    color: #fff!important;
}

@media (max-width: 767px){
    .btn-trash{
        position: absolute;
        margin-left: -35px;
        width: 30px;
        height: 30px;
        padding: 0!important;
        background: #f00!important;
        border-radius: 10px!important;
        border: none!important;
        color: #fff!important;
    }
    .btn-trash svg{
        font-size: 13px;
    }
}
#users .resume {
    margin-left: 260px;
    width: 100%;
}

#users .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #users .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#users .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}


#users .nav .icon-search {
    right: 31px;
    height: 28px;
    margin: 10px 0;
}

#users .nav .form-control:focus {
    background-color: #0000!important;
}

#users .nav .input-group>.form-control {
    height: calc(1.1em + .80rem + 5px);
    margin: 10px 0;
}

#users {
    margin-left: 0;
    margin-right: 0;
}

#users .list-register-users {
    padding: 15px 0;
    border-radius: 15px;
}

#users .list-register-users p, #users .list-register-users svg {
    margin: 20px 0px 0px 0px;
}

#users .list-register-users .description .address svg, #users .list-register-users .description .dt-birth svg {
    margin-right: 10px;
    color: #fddda7;
}

#users .list-register-users .description .btn-function a {
    margin: 10px;
}
#user-update-data .resume {
    margin-left: 260px;
    width: 100%;
}

#user-update-data .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #user-update-data .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#user-update-data {
    margin-top: 20px;
}

#user-update-data #upload-foto-profile {
    cursor: pointer;
}

#user-update-data .link-update-photo{
    background-color: #fff;
    color: #2c7eeb!important;
    border-color: #ffffff;
    margin-top: -10px;
}

#user-update-data #upload-foto-profile input[type="file"] {
    display: none;
}

#user-update-data #upload-foto-profile p {
    color: #686464;
}

#user-update-data #upload-foto-profile p svg {
    margin-right: 5px;
}

#user-update-data .forms {
    border-radius: 15px;
}

#user-update-data .forms .figure { 
    width: 150px;
    height: 150px;
}

#user-update-data .forms .figure img {
    margin-top: 15px;
    background: no-repeat center;
    background-size: cover;    
    width: 150px;
    height: 150px;
}

#user-update-data .forms button {
    color: #686464;
}

#user-update-data .forms form {
    margin: 20px 0;
}

#user-update-data .forms form input, #user-update-data .forms form select {
    border-radius: 25px;
}

#user-update-data .forms form .form-group .label-dt-brith {
    float: left;
    font-size: 14px;
    margin: 0px 0px -2px 15px;
    color: #6c79a5;
}

#user-update-data .forms form .form-group .form-check {
    float: left;
    margin: 10px 15px 0px 0
}

#user-update-data .forms form .update-address {
    margin-bottom: 70px;
}

#user-update-data .forms form .link-update-password {
    display: inline-block;
    padding: 1rem 0rem;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
}

#user-update-data .forms form .btn-create {
    margin-right: 10px;
}

#user-update-data .link-delete-user {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    background-color: #fff0!important;
    border-color: #fff0!important;
    color: #686464;
}

#navbar-custom {
  padding-top: 10px;
}

.bg-dark-custom {
  background-color: #000 !important;
}

#logo {
  padding-right: 15px;
  margin-top: -7px;
}

#outra-logo {
  padding-right: 15px;
  margin-top: -7px;
}

#nav-custom-top {
  flex: unset;
  display: flex;
  margin-top: 5px;
  padding-left: 0;
  padding-right: 0;
}

#nav-custom-top .navbar-brand {
  margin-right: 0rem;
}

.destaque-btn {
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-radius: 40px;
  border-color: #fd880d;
}

.input-group {
  z-index: 15;
}

.search-input {
  width: 60vh !important;
}

.input-group > .form-control {
  border-radius: 25px !important;
  height: calc(1.9em + 0.8rem + 5px);
  font-size: 1.2rem;
}

.icon-search {
  background: #fff;
  color: #fd880d;
  height: 38px;
  padding: 3px;
  right: 13px;
  position: absolute;
  top: 8px;
  cursor: pointer;
}

#nav-custom-top .input-group-append {
  z-index: 10;
}

#nav-custom-top .navbar-nav {
  flex-direction: row;
}

#nav-custom-top .col-12,
.col-lg-8,
.col-lg-4 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

#nav-custom-top #image-profile-icon-dropdown {
  padding: 0.25rem 1.5rem;
}

#image-profile-icon-dropdown {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.5rem 0;
  grid-gap: 16px;
  gap: 16px;
}

#image-profile-icon-dropdown div pre {
  margin: 0;
}

#nav-custom-top #nav-dropdown-custom #image-profile-icon-dropdown img {
  float: left;
  margin: 0 10px 0px 0px;
}

#nav-custom-top #image-profile-icon-dropdown pre {
  margin-bottom: 5px;
}

#nav-custom-top .dropdown-menu .dropdown-item svg {
  margin-right: 15px;
}

#notification-icon {
  margin: 0 10px;
  cursor: pointer;
}

#navbar-custom .icon-bell {
  color: #fd880d;
  margin-top: 10px;
}

#navbar-custom .search-list {
  position: absolute;
  background: #fff;
  width: 98%;
  padding: 50px 0px;
  margin-top: -35px;
  border-radius: 20px;
  z-index: 10;
  font-size: 18px;
}

#navbar-custom .search-list li {
  list-style-type: none;
  margin: 0px 0;
  padding: 6px 20px;
  height: 40px;
}

#navbar-custom .search-list li .icon-search-list {
  color: #beb5b5;
  margin: 0 0px 0 0px;
}

#navbar-custom .search-list a {
  color: rgb(87, 78, 78);
  font-weight: 600;
  margin-top: 5px;
  width: 100%;
  text-decoration: none;
}

#navbar-custom .search-list li:hover {
  background: rgb(58, 166, 255);
  font-weight: 600;
}

#basic-nav-dropdown {
  color: #fff;
  margin-right: 10px;
}

#nav-dropdown-custom {
  margin: 10px;
}

#nav-dropdown-custom img {
  float: left;
  margin-right: 10px;
}

.image-profile-icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

#nav-extra-custom {
  text-transform: uppercase;
  font-weight: 550;
  font-size: 13px;
  margin: 0px -13px 0px 0px;
  justify-content: center;
}

#nav-extra-custom .nav-link {
  color: #fff;
  margin: 2px 7px;
  height: 34px;
  padding: 5px;
  text-align: center;
}

#basic-nav-dropdown {
  height: 45px;

  border-radius: 22px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #fd880d;

  margin: 0;
  padding: 11px 38px 11px 28px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-transform: translateX(-12px);

          transform: translateX(-12px);
  transition: 0.1s ease;

  position: absolute;
  top: -22px;
  left: -6px;
  width: 106%;
}

#basic-nav-dropdown:hover {
  background-color: #ca6d0a;
}

@media screen and (min-width: 1297px) {
  #basic-nav-dropdown {
    width: 100%;
    max-width: 201px;
    padding: 11px 44px 11px 22px;
    position: unset;
  }
}

@media screen and (min-width: 1400px) {
  #basic-nav-dropdown {
    max-width: unset;
  }
}

#basic-nav-dropdown::after {
  margin-left: 122px;
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.navbar button {
  background-color: #fd880d;
  color: black;
}

.navbar button .navbar-toggler-icon {
  color: black;
}

#update-password-user .bg-clear-custom {
    background: #000;
    margin-top: 20px;
    border-radius: 25px;
    box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    width: 100%;
    max-width: 500px;
}

#update-password-user .update-password-user-form{
    margin-top: 25px;
}

#update-password-user .update-password-user-form form {
    margin-top: 20px;
    padding: 0 40px;
    justify-self: start;
}


#update-password-user .update-password-user-form form .form-group input {
    margin-top: 5px;
    border-radius: 25px;
}

#update-password-user .update-password-user-form form .buttons {
    margin-bottom: 40px;
}

#update-password-user .update-password-user-form form .btn-create {
    margin-right: 10px;
}

#messages .resume {
    margin-left: 260px;
    width: 100%;
}

#messages .resume-mobile {
    margin-left: 100px;
    width: 100%;
}

@media (min-width: 992px){
    #messages .resume-mobile {
        margin-left: 160px;
        width: 100%;
    }
}

#messages .nav{
    margin-top: -15px;
    margin-left: -15px;
    padding: 10px;
}


#messages .nav .icon-search {
    right: 31px;
    height: 28px;
    margin: 10px 0;
}

#messages .nav .form-control:focus {
    background-color: #0000!important;
}

#messages .nav .input-group>.form-control {
    height: calc(1.1em + .80rem + 5px);
    margin: 10px 0;
}

#messages {
    margin-left: 0;
    margin-right: 0;
}

#messages .list-messages {
    padding: 15px 0;
    border-radius: 15px;
}

#messages .list-messages p, #messages .list-messages svg {
    margin: 20px 0px 0px 0px;
}

#account-disabled {
    margin: 50px 0;
}

#account-disabled .title-custom-h2 {
    margin: 50px 0;
}

#account-disabled h4 {
    margin: 50px 0;
}

#account-disabled button {
    margin: 50px 0;
    width: 50%;
    font-size: 30px!important;
    height: 65px;
}

@media (max-width: 767px){
    #account-disabled button {
        width: 100%;
    }
}
/* CSS Global */

body {
  background-color: #f8f8f8;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI",
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-top: 80px;
}

@media (min-width: 1297px) {
  body {
    margin-top: 130px;
  }
}

a {
  color: #686464;
}

.title-custom-h2 {
  margin: 20px 0;
  font-weight: 550;
  color: rgb(103, 96, 96);
  text-transform: uppercase;
}

.title-custom-h3 {
  margin: 20px 0;
  font-weight: 550;
  color: rgb(103, 96, 96);
  text-transform: uppercase;
}

.btn-create {
  color: #fff !important;
  background-color: #faa00a !important;
  border-color: #faa00a !important;
  width: 150px;
  height: 40px;
  border-radius: 25px !important;
  padding-top: 8px;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-create:hover {
  color: #000 !important;
  /* background-color: #fff !important; */
}

.btn-access {
  color: #fff !important;
  background-color: #8498c1 !important;
  border-color: #7d96c5 !important;
  width: 150px;
  height: 40px;
  border-radius: 25px !important;
  padding-top: 10px;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-info-status {
  color: #fff !important;
  background-color: #faa00a !important;
  border-color: #faa00a !important;
  width: 150px;
  height: 54px;
  padding: 12px;
  padding-top: 10px;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-trans-option {
  height: 50px;
  width: 260px;
  border: #dedede solid 1px;
  background-color: #fff;
  margin: 20px 5px;
  border-radius: 0;
}

.btn-cancel {
  color: #686464 !important;
  border-color: #faa00a !important;
  width: 150px;
  height: 40px;
  background-color: #fff;
  border-radius: 25px !important;
  margin: 10px 0 0 0;
  padding: 0.475rem 0.75rem;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-link {
  color: #686464 !important;
  border-color: #fff !important;
  width: 100px;
  height: 40px;
  background-color: #fff;
  border-radius: 25px !important;
  margin: 10px 0 0 0;
  padding: 0.475rem 0.75rem;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn-link:hover {
  color: #614e4e !important;
  background-color: #fff;
}

.btn-none {
  background: none;
  border: none;
}

.btn-login {
  color: #fff !important;
  border-radius: 25px !important;
  margin-left: 45px !important;
  font-size: 14px !important;
}

.btn-login b {
  color: #faa00a !important;
}

.vl {
  margin-left: 15px;
  font-weight: 600;
  color: #faa00a;
}

.form-group input,
.form-group select,
.form-group textarea {
  margin-top: 5px;
  border-radius: 25px;
}

#loading {
  width: 100%;
  height: 100%;
  background: #0708085e;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#loading .spinner-border {
  color: #ff8400;
  width: 5rem;
  height: 5rem;
}

.kep-login-facebook {
  margin-left: 10px;
  border-radius: 3px;
  width: 50px;
  height: 52.5px;
  padding: 7px 10px 10px 10px !important;
}

.carousel-root {
  cursor: pointer;
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: #f8f8f8;
}

.carousel .slide iframe {
  width: calc(100% - 0px);
  margin: 0 0px 40px;
}

/* .modal-content {
  background-color: #cdc6c6;
} */

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

